
<div>
  <b-field :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0] }" :custom-class="verified ? 'is-verified' : ''">
    <div class="is-flex">
      <b-select v-model="innerValue.name" :disabled="$attrs.disabled">
        <option v-for="currency in allowedCurrencies" :key="currency.code" :value="currency.code">{{ currency.code }}</option>
      </b-select>
      <b-input ref="valueInput" :value="innerValue.value" type="number" class="value-input" :disabled="$attrs.disabled" @blur="valueChanged" />
    </div>
  </b-field>
  <p class="help is-danger">{{ errors[0] }}</p>
</div>
