
<div class="field">
  <div :class="{ 'field-label-table-horizontal': horizontal }">
    <label class="label">{{ collection.label }}{{ collection.is_required ? '*' : '' }}</label>
    <p v-if="message" class="help">{{ message }}</p>
  </div>
  <div :class="{ 'field-body': horizontal, 'field-body-table-horizontal': horizontal }">
    <div class="field">
      <div class="dynamic-data-table view-table page-filtered">
        <b-table :data="innerValue" class="sket-table" @click="openEditRowDialog">
          <template slot-scope="props">
            <b-table-column label="Verified" width="20">
              <img v-if="verified[props.row.id]" src="@/assets/icons/figma/Checkbox.svg" style="height: 24px" />
              <img v-if="!verified[props.row.id]" src="@/assets/icons/figma/Checkbox-empty.svg" style="height: 24px" />
            </b-table-column>
            <b-table-column v-for="(c, index) in collectionChildrenSorted" :key="index" :field="c.name" :label="c.label">
              <DynamicDataValueRenderer :field="c" :type="c.type" :value="props.row[c.name]" />
            </b-table-column>
            <b-table-column v-if="collection.visibility !== 'READ_ONLY' && !fieldDisabled(props.row)" class="actions">
              <a @click.prevent.stop="openEditRowDialog(props.row)">
                <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/pencil.svg')" />
              </a>
              <a @click.prevent.stop="openConfirmDeleteDialog(props.row, index)">
                <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/trash.svg')" />
              </a>
            </b-table-column>
            <b-table-column v-else></b-table-column>
          </template>
          <template slot="empty">
            <div class="has-text-grey">
              No data has been added
            </div>
          </template>
          <template slot="footer">
          </template>
        </b-table>
      </div>
      <div class="table-toolbar">
        <a v-if="collection.visibility !== 'READ_ONLY' && !disabled" @click="openAddRowDialog">
                <span class="link-icon-primary">
                  <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/plus.svg')" />
                </span>
          Add a row
        </a>
        <div class="is-pulled-right">
          <a @click.prevent.stop="openConfirmDeleteAllDialog()" v-if="isBackOffice"><img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/trash.svg')" /> Delete all</a>
        </div>
      </div>
    </div>
  </div>
</div>
