import CaseService from '@/services/CaseService';
import CaseRelationService from '@/services/CaseRelationService';
import FrontendService from '@/services/FrontendService';
import steps from './steps.module';
import Enums from '@/utils/enums';

// registrar: {
//   id: null,
//     firstname: '',
//     lastname: '',
//     email: '',
//     agree_to_tc_pp: false
// }
function generateId() {
  return 'new-' + Math.random();
}

function createEmptyClaim({ whitelabel_id }) {
  const claim = {
    id: 'new',
    whitelabel_id,
    intake_meta: {
      completed_steps: {}
    },
    caseRelations: []
  };

  return claim;
}

const initialState = () => {
  return {
    document_scheme: [],
    casetype: {},
    claim: createEmptyClaim({})
  };
};

// case is a RESERVED KEYWORD
export default {
  namespaced: true,
  state: initialState(),
  modules: {
    'steps': steps
  },
  getters: {
    case_relations_complete(state) {
      const intake_meta = state.claim.intake_meta || {};
      const completedSteps = intake_meta.completed_steps || {};
      return completedSteps['general-info'] && completedSteps['claimant-info'] && completedSteps['documents'] && completedSteps['review-and-submit'];
    },
    registrar(state) {
      return state.claim.caseRelations.find(relation => relation.type === 'REGISTRAR');
    }
  },
  actions: {
    async routeChange({ rootState, state, dispatch, commit }, { casetype_id, case_id }) {
      const whitelabel_id = rootState.whitelabel.whitelabel_id;

      const schemesResponse = await FrontendService.getCaseRelationDocumentScheme();
      commit('setDocumentScheme', schemesResponse.data.data);

      let old_case_id = state.claim.id;

      if (state.casetype.id !== casetype_id) {
        const response = await FrontendService.getCaseType(casetype_id, { whitelabel_id });
        const casetype = response.data;
        commit('setCaseType', casetype);
        await dispatch('intakeng/steps/reset', {
          is_contract_enabled: casetype.is_contract_enabled,
          has_fields: casetype.forms.length > 0 && casetype.forms[0].fields.length > 0,
          whitelabel: rootState.whitelabel.whitelabel
        }, { root: true });
        old_case_id = 'force_reload_case';
      }

      if (old_case_id !== case_id) {
        if (case_id === 'new') {
          await dispatch('resetIntake', { casetype_id, case_id });
        } else {
          const response = await FrontendService.getCase(case_id, { whitelabel_id });
          const claim = response.data;
          commit('setCase', claim);
          if (claim?.intake_meta?.completed_steps) {
            await dispatch('intakeng/steps/calculateCurrentStep', claim.intake_meta.completed_steps, { root: true });
          }
        }
      }
    },
    async resetIntake({ dispatch, commit, rootState, state }, { casetype_id, case_id }) {
      const user = rootState.authentication.user;
      const whitelabel_id = rootState.whitelabel.whitelabel_id;

      const response = await FrontendService.getCaseType(casetype_id, { whitelabel_id });
      const casetype = response.data;
      await commit('setCaseType', casetype);
      await dispatch('intakeng/steps/reset', {
        is_contract_enabled: casetype.is_contract_enabled,
        has_fields: casetype.forms.length > 0 && casetype.forms[0].fields.length > 0,
        whitelabel: rootState.whitelabel.whitelabel
      }, { root: true });

      if (case_id.startsWith('new')) {
        const claim = createEmptyClaim({ whitelabel_id })
        commit('setCase', claim, user);
      } else {
        const claim = Object.assign({}, state.claim, {
          whitelabel_id,
          intake_meta: {
            completed_steps: {}
          },
          caseRelations: []
        });
        commit('setCase', claim, user);
      }

      if (user) {
        const registrar_user = user.contact || {};
        await dispatch('createRelation', { type: 'REGISTRAR', contact_type: 'PERSON', initial_contact: registrar_user });
      }
    },
    async saveGeneralInfo({ rootState, state, dispatch, commit, getters }, {contactTreeType, contactTreeVariation}) {
      const previousContactTreeType = state.claim?.intake_meta?.contract_tree_type || null;
      if (previousContactTreeType !== contactTreeType) {
        await dispatch('resetIntake', { casetype_id: state.casetype.id, case_id: (state.claim?.id || 'new') });

        await dispatch('setCaseMeta', {
          contract_tree_type: contactTreeType,
          contact_tree_variation: contactTreeVariation,
          completed_steps: { 'general-info': true }
        });

        const user = rootState.authentication.user || {};
        // const registrar = user.contact || {};

        // console.log('registrar', getters.registrar);
        if (!getters.registrar) {
          // await dispatch('createRelation', { type: 'REGISTRAR', contact_type: 'PERSON', initial_contact: registrar });
          await dispatch('createRelation', { type: 'REGISTRAR', contact_type: 'PERSON' });
        }

        switch (contactTreeType) {
          case 'PERSON':
            if (contactTreeVariation === 'SINGLE' || contactTreeVariation === 'MULTIPLE') {
              const registrarContactId = getters.registrar?.contact?.id || 'new';
              const initial_contact = !registrarContactId.startsWith('new') ? getters.registrar?.contact : null;
              await dispatch('createRelation', { type: 'CLAIMANT', contact_type: 'PERSON', initial_contact: initial_contact?.type === 'PERSON' ? initial_contact : null });
            }

            if (contactTreeVariation === 'MULTIPLE') {
              await dispatch('createRelation', { type: 'CLAIMANT', contact_type: 'PERSON' });
            }
            break;
          case 'COMPANY':
            {
              const companyRelation = await dispatch('createRelation', { type: 'CLAIMANT', contact_type: 'COMPANY' });
              const registrarContactId = getters.registrar?.contact?.id || 'new';
              const initial_contact = !registrarContactId.startsWith('new') ? getters.registrar?.contact : null;
              if (!user) {
                await dispatch('createRelation', {
                  type: 'CONTACT',
                  contact_type: 'PERSON',
                  initial_contact,
                  relation_parent_id: companyRelation.id
                });
              }
            }
            break;
        }
      }

      await dispatch('intakeng/steps/next', null, { root: true });
    },
    async saveDynamicData({ commit, dispatch }, dynamicData) {
      await commit('setDynamicData', dynamicData);
      await dispatch('intakeng/steps/next', null, { root: true });
    },
    async createRelation({ state, commit, rootState }, { type, contact_type, initial_contact, relation_parent_id }) {
      let language = null;

      if (type === 'REGISTRAR') {
        commit('removeRegistrar');
        language = rootState.i18n.lang;
      }

      const contact = initial_contact || {
        id: generateId(),
        type: contact_type,
        commercial_type: Object.keys(Enums.CommercialType)[0],
        name: '',
        firstname: '',
        lastname: '',
        email: '',
        birth_date: null,
        address: '',
        address_second: '',
        postal_code: '',
        city: '',
        country: '',
        primary_phone: '',
        secondary_phone: '',
        vat_number: '',
        language
      };

      const relation = {
        id: generateId(),
        contact,
        type,
        documents: []
      };

      const documentSchemes = state.document_scheme.filter(dc => {
        return contact_type === dc.contact_type && type === dc.relation_type;
      });

      for (const documentScheme of documentSchemes) {
        relation.documents.push({
          id: generateId(),
          allow_multiple_files: documentScheme.allow_multiple_files,
          document_type_id: documentScheme.documentType.id,
          documentType: documentScheme.documentType,
          files: [
            {
              id: generateId(),
              url: '',
              filename: ''
            }
          ]
        });
      }

      if (relation_parent_id) {
        relation.parent_id = relation_parent_id;
      }

      commit('addRelation', relation);
      return relation;
    },
    // async fetchCaseType({ commit }, casetype_id) {
    //   let response = await CaseTypeService.getOne(casetype_id);
    //   let casetype = response.data;
    //   commit('setCaseType', casetype);
    // },
    async fetchCase({ commit }, case_id) {
      // let response = await CaseService.getOne(case_id, { _related: ['caseRelations', 'caseRelations.contact'] });
      // let claim = response.data;
      // commit('setCase', omit(claim, 'caseRelations'));
      //
      // let relations = get(claim, ['caseRelations']);
      // relations.sort((a, b) => {
      //   return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      // });
      // commit('setRelations', relations);

      // let documentsResponse = await CaseRelationDocumentService.getAll({ case_id, _related: ['documentType', 'files'] });
      // let documents = documentsResponse.data.data;

      // commit('setDocuments', documents);
    },
    async setCaseMeta({ commit, state }, intake_meta) {
      const newMeta = Object.assign({}, state.claim.intake_meta, intake_meta);
      const completedSteps = newMeta.completed_steps;
      // await CaseService.update(state.claim.id, { intake_meta: newMeta, case_relations_complete });
      commit('patchCaseMeta', newMeta);
    },
    async resetCaseContactTree({ state, dispatch }) {
      // await CaseRelationService.resetRelations(state.claim.id);
      // Refresh the claim
      await dispatch('fetchCase', state.claim.id);
    },
    async changeRelationContact({ commit, state }, { relation_id, contact_id }) {
      let response = await CaseRelationService.update(relation_id, { contact_id });
      let updatedRelation = response.data;
      let relationResponse = await CaseService.getRelation(state.claim.id, updatedRelation.id, { _related: ['contact'] });
      let relation = relationResponse.data;

      commit('updateRelation', { id: relation.id, relation });
    },
    async addRelationDocumentFile({ commit, state }, { case_relation_document_id, url }) {
      // const response = await CaseService.createRelationFile(state.claim.id, { case_relation_document_id, url });
      // const file = response.data;
      const file = {
        id: generateId(),
        url: '',
        filename: ''
      };
      commit('addDocumentFile', { case_relation_document_id, file });
    },
    async removeRelationDocumentFile({ commit, state }, { case_relation_document_id, file_id }) {
      // await CaseService.deleteRelationFile(state.claim.id, file_id);
      commit('removeDocumentFile', { case_relation_document_id, file_id });
    },
    async updateRelationDocumentFile({ commit, state }, { case_relation_document_id, file_id, url, filename }) {
      // let fileResponse = await CaseService.updateRelationFile(state.claim.id, file_id, { url });
      // let file = fileResponse.data;

      const file = {
        id: file_id,
        case_relation_document_id,
        url,
        filename
      };
      commit('updateDocumentFile', { case_relation_document_id: file.case_relation_document_id, file });
    },
    async generateContract({ state }) {
      await CaseService.generateContract(state.claim.id);
    },
    async updateCaseLifecycle(args, { caseId, nextState }) {
      await CaseService.updateLifecycle(caseId, nextState);
    },
    async save({ state, commit }, payload) {
      const response = await FrontendService.saveCase(Object.assign({}, state.claim, payload, {
        casetype_id: state.casetype.id
      }));
      const claim = response.data;
      await commit('setSavedClaim', claim);
    }
  },
  mutations: {
    setSavedClaim(state, claim) {
      state.claim.id = claim.id;
    },
    setDocumentScheme(state, scheme) {
      state.document_scheme = scheme;
    },
    setCaseType(state, casetype) {
      state.casetype = casetype;
    },
    setCase(state, claim) {
      state.claim = claim;
    },
    patchCaseMeta(state, intake_meta) {
      state.claim.intake_meta = Object.assign(state.claim.intake_meta, intake_meta);
    },
    setRelations(state, relations) {
      state.relations.splice(0, state.relations.length, ...relations);
    },
    setDocuments(state, documents) {
      state.documents.splice(0, state.documents.length, ...documents);
    },
    addDocuments(state, documents) {
      state.documents = state.documents.concat(documents);
    },
    removeDocuments(state, params) {
      const documents = state.documents.filter(document => {
        let same = true;
        for (let k in params) {
          if (document[k] !== params[k]) same = false;
        }
        return !same;
      });
      state.documents.splice(0, state.documents.length, ...documents);
    },
    addRelation(state, relation) {
      state.claim.caseRelations.push(relation);
    },
    updateRelation(state, { id, relation }) {
      let index = state.claim.caseRelations.findIndex(relation => relation.id === id);
      if (index !== -1) state.claim.caseRelations.splice(index, 1, relation);
    },
    removeRelation(state, case_relation_id) {
      const index = state.claim.caseRelations.findIndex(relation => relation.id === case_relation_id);
      state.claim.caseRelations.splice(index, 1);
    },
    removeRegistrar(state) {
      const index = state.claim.caseRelations.findIndex(relation => relation.type === 'REGISTRAR');
      if (index > -1) {
        state.claim.caseRelations.splice(index, 1);
      }
    },
    setContact(state, contact) {
      for (let i = 0; i < state.claim.caseRelations.length; i++) {
        const relation = state.claim.caseRelations[i];
        if (relation.contact_id === contact.id || relation.contact.id === contact.id) {
          state.claim.caseRelations.splice(i, 1, Object.assign(relation, { contact }));
        }
      }
    },
    updateRelationContact(state, { relationId, contact }) {
      const index = state.claim.caseRelations.findIndex(relation => relation.id === relationId);
      if (index !== -1) {
        state.claim.caseRelations[index].contact = Object.assign({}, contact);
        for (const caseRelation of state.claim.caseRelations) {
          if (caseRelation.contact.id === contact.id) {
            caseRelation.contact = Object.assign({}, contact); // overwrite same contacts
          }
        }
      }
    },
    addDocumentFile(state, { case_relation_document_id, file }) {
      for (const relation of state.claim.caseRelations) {
        const index = relation.documents.findIndex(document => document.id === case_relation_document_id);
        if (index > -1) {
          relation.documents[index].files.push(file);
        }
      }
    },
    removeDocumentFile(state, { case_relation_document_id, file_id }) {
      for (const relation of state.claim.caseRelations) {
        const index = relation.documents.findIndex(document => document.id === case_relation_document_id);
        if (index > -1) {
          const fileIndex = relation.documents[index].files.findIndex(docFile => docFile.id === file_id);
          relation.documents[index].files.splice(fileIndex, 1);
        }
      }
    },
    updateDocumentFile(state, { case_relation_document_id, file }) {
      for (const relation of state.claim.caseRelations) {
        const index = relation.documents.findIndex(document => document.id === case_relation_document_id);
        if (index > -1) {
          const fileIndex = relation.documents[index].files.findIndex(docFile => docFile.id === file.id);
          if (fileIndex !== -1) relation.documents[index].files.splice(fileIndex, 1, file);
        }
      }
    },
    setDynamicData(state, dynamicData) {
      state.claim.dynamicData = Object.assign({}, dynamicData);
    }
  }
};
