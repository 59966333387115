import http from '@/utils/http';

class FrontendService {
  async getCaseTypes(params) {
    return http({
      url: `api/frontend/casetypes`,
      method: 'get',
      params
    });
  }

  async getCaseRelationDocumentScheme(params) {
    return http({
      url: `api/frontend/case-relation-document-scheme`,
      method: 'get',
      params
    });
  }

  async getCaseType(id, params) {
    return http({
      url: `api/frontend/casetypes/${id}`,
      method: 'get',
      params,
      ignore_404: true
    });
  }

  getNews({ casetype_id, news_id }) {
    if (news_id) {
      return http({
        url: `api/frontend/casetypes/${casetype_id}/news/${news_id}`,
        method: 'GET'
      });
    }

    return http({
      url: `api/frontend/casetypes/${casetype_id}/news`,
      method: 'GET',
      params: {
        _orderBy: '-publish_at'
      }
    });
  }

  async getCase(id, params) {
    return http({
      url: `api/frontend/cases/${id}`,
      method: 'get',
      params
    });
  }

  saveCase(claim) {
    return http({
      url: `api/frontend/cases`,
      method: 'post',
      data: claim
    });
  }

  getPresignedPost(caseId, fileName) {
    return http({
      url: `api/frontend/sign`,
      method: 'post',
      data: { caseId, fileName }
    });
  }

  getSignedReadUrl(s3Url, filename) {
    return http({
      url: `api/frontend/sign-read`,
      method: 'post',
      data: { url: s3Url, filename }
    });
  }

  async checkEmail(email) {
    return http({
      url: `api/frontend/check-email`,
      method: 'post',
      data: { email }
    });
  }

  checkContract(caseId) {
    return http({
      url: `api/frontend/check-contract/${caseId}`,
      method: 'get',
      data: { caseId }
    });
  }

  getContract(caseId) {
    return http({
      url: `api/frontend/get-contract/${caseId}`,
      method: 'get',
      data: { caseId }
    });
  }

  signClaimDataUpload(fileName) {
    return http({
      url: `api/frontend/claimdata/sign-upload`,
      method: 'post',
      data: { fileName }
    });
  }
}

export default new FrontendService();
