
<div class="field upload-multiple-control" :class="{ 'is-horizontal': horizontal }">
  <div class="is-normal" :class="{ 'field-label': horizontal }">
    <label class="label">
      <div v-if="verified" class="is-verified"></div>
      {{ label }}
    </label>
  </div>
  <div :class="{ 'field-body': horizontal }">
    <div class="field" v-if="!disabled">
      <div v-if="internalValue && internalValue.length > 0" class="control files-list">
        <ul v-if="internalValue.length > 0">
          <li v-for="(upload, idx) in internalValue" :key="idx">
            <a @click.prevent="goToS3(upload.url, upload.legacy_name || getFilename(upload.url))">{{ upload.legacy_name || getFilename(upload.url) }}</a>
            <a v-if="!disabled" class="deselect-upload is-pulled-right" @click.prevent.stop="removeFile(idx)"><img src="@/assets/icons/Remove.svg"/></a>
          </li>
        </ul>
      </div>
      <div class="control image-input" @click="chooseImage">
        <div class="placeholder has-file-selected">
          <div style="width: 100%;">
            <div style="text-align: center;">{{ $t('form.addFile') }}</div>
            <div><b-progress v-if="isUploading" type="is-info" :internalValue="percentCompleted"></b-progress></div>
          </div>
        </div>
        <input ref="fileInput" class="file-input" type="file" :accept="accept" @change="fileChange($event)" />
      </div>
      <p v-if="message" class="help">{{ message }}</p>
    </div>
    <div v-else class="field">
      <div class="control image-input disabled">
        <div class="placeholder has-file-selected">
          <div style="width: 100%;">
            <div style="text-align: center;">
              <ul v-if="internalValue && internalValue.length > 0">
                <li v-for="(upload, idx) in internalValue" :key="idx">
                  <a @click.prevent="goToS3(upload.url, upload.legacy_name || getFilename(upload.url))">{{ upload.legacy_name || getFilename(upload.url) }}</a>
                  <a v-if="!verified" class="deselect-upload" @click.prevent.stop="removeFile(idx)"><img src="@/assets/icons/Remove.svg"/></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p v-if="message" class="help">{{ message }}</p>
    </div>
  </div>
</div>
