<template>
  <b-navbar class="user-navbar" fixed-top>
    <template slot="brand">
      <b-navbar-item :to="{ name: 'cases' }" tag="router-link">
        <img src="@/assets/images/main-logo.png" height="42" width="121" class="logo" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item v-if="hasRole(['ADMIN', 'CASETYPE_USER'])" :to="{ name: 'cases', query: savedQuery('cases') }" :active="isActiveRoute('cases')" tag="router-link">
        <span :class="{ 'hidden-box': isActiveRoute('cases') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/claims.svg')" />
        </span>
        <span :class="{ 'hidden-box': !isActiveRoute('cases') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/claims_filled.svg')" />
        </span>
        Claims
      </b-navbar-item>
      <b-navbar-item v-if="hasRole(['ADMIN', 'CASETYPE_USER'])" :to="{ name: 'casetypes', query: savedQuery('casetypes') }" :active="isActiveRoute('casetypes')" tag="router-link">
        <span :class="{ 'hidden-box': isActiveRoute('casetypes') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/cases.svg')" />
        </span>
        <span :class="{ 'hidden-box': !isActiveRoute('casetypes') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/cases_filled.svg')" />
        </span>
        Cases
      </b-navbar-item>
      <b-navbar-item v-if="hasRole(['ADMIN'])" :to="{ name: 'contacts', query: savedQuery('contacts') }" :active="isActiveRoute('contacts')" tag="router-link">
        <span :class="{ 'hidden-box': isActiveRoute('contacts') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/contacts.svg')" />
        </span>
        <span :class="{ 'hidden-box': !isActiveRoute('contacts') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/contacts_filled.svg')" />
        </span>
        Contacts
      </b-navbar-item>
    </template>

    <template slot="end">
      <span class="navbar-item is-mobile is-hidden-desktop">{{ user && user.contact ? user.contact.firstname + ' ' + user.contact.lastname : '?' }}</span>
      <b-navbar-item v-if="hasRole(['ADMIN'])" tabindex="-1" class="p-0" @click="cycleTheme()">
        <span :class="{ 'hidden-box': theme === 'main' }">
          <img v-svg-inline class="theme-switch-icon inline-icon" :src="require('@/assets/icons/fa/fas/moon.svg')" />
        </span>
        <span :class="{ 'hidden-box': theme === 'main_dark' }">
          <img v-svg-inline class="theme-switch-icon inline-icon" :src="require('@/assets/icons/fa/fas/sun.svg')" />
        </span>
      </b-navbar-item>
      <b-navbar-item v-if="hasRole(['ADMIN'])" tag="router-link" :to="{ name: 'notifications' }">
        <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/notification.svg')" />
        <span v-if="notificationsCount > 0" class="notification-counter">{{ notificationsCount }}</span>
      </b-navbar-item>
      <b-navbar-dropdown :label="initials" :arrowless="true" class="navbar-user-dropdown">
        <b-navbar-item tag="router-link" :to="{ name: 'home' }">Front office</b-navbar-item>
        <b-navbar-item v-if="hasRole(['ADMIN'])" tag="router-link" :to="{ name: 'auditlog' }">Audit</b-navbar-item>
        <b-navbar-item v-if="hasRole(['ADMIN'])" tag="router-link" :to="{ name: 'whitelabels' }">Whitelabel</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'settings' }">Account settings</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'faq' }">{{ $t('navbar.faq') }}</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'terms' }">{{ $t('navbar.terms') }}</b-navbar-item>
        <b-navbar-item :href="'mailto:' + CONTACT_MAIL">Contact us</b-navbar-item>
        <b-navbar-item @click="logout">Log out</b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import NavigationMixin from '@/mixins/NavigationMixin';
import store from '@/store/store';

export default {
  name: 'Navbar',
  mixins: [NavigationMixin],
  data() {
    return {
      CONTACT_MAIL: import.meta.env.VITE_CONTACT_MAIL
    };
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      global_role: 'authentication/global_role'
    }),
    ...mapState('notification', ['notificationsCount']),
    ...mapState('theme', ['theme']),
    initials() {
      if (this.user && this.user.contact && this.user.contact.firstname && this.user.contact.lastname) {
        return (this.user.contact.firstname.substr(0, 1) + this.user.contact.lastname.substr(0, 1)).toUpperCase();
      } else if (this.user && this.user.contact && this.user.contact.name) {
        return this.user.contact.name.substr(0, 2).toUpperCase();
      } else {
        return '?';
      }
    }
  },

  methods: {
    hasRole(roles) {
      return roles.indexOf(store.getters['authentication/global_role']) > -1
    },
    ...mapActions('theme', ['cycleTheme']),
    logout() {
      this.$store.dispatch('authentication/logout');
    },
    isActiveRoute(routeNames) {
      if (!Array.isArray(routeNames)) {
        routeNames = [routeNames];
      }
      return !!this.$route.matched.find(route => routeNames.find(name => name === route.name || (route.meta && route.meta.navbar === name)));
    }
  }
};
</script>
