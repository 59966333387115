<template>
  <div>
    <b-field :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0] }" :custom-class="verified ? 'is-verified' : ''">
      <div class="is-flex">
        <b-select v-model="innerValue.name" :disabled="$attrs.disabled">
          <option v-for="currency in allowedCurrencies" :key="currency.code" :value="currency.code">{{ currency.code }}</option>
        </b-select>
        <b-input ref="valueInput" :value="innerValue.value" type="number" class="value-input" :disabled="$attrs.disabled" @blur="valueChanged" />
      </div>
    </b-field>
    <p class="help is-danger">{{ errors[0] }}</p>
  </div>
</template>

<script>
import { validate, normalizeRules } from 'vee-validate';
import Enums from '@/utils/enums';
import i18n from '@/i18n/i18n';
import currencyList from '@/utils/currencies.json';

export default {
  components: {},
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: Object,
      default: () => ({
        value: 0,
        name: 'EUR'
      })
    },
    verified: {
      type: Boolean,
      default: false
    },
    allowedCurrencies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      innerValue: {
        value: 0,
        name: 'EUR'
      },
      errors: [],
      CURRENCIES: Enums.CURRENCIES
    };
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    async valueChanged() {
      const value = this.$refs.valueInput.newValue;
      this.errors = [];
      if (this.rules) {
        const normalizedRules = normalizeRules(this.rules);
        const rules = { ...normalizedRules };

        const result = await validate(value, rules, {
          name: this.name || this.fieldName
        });

        this.errors = result.errors.map(msg => {
          return i18n.t(msg).replace('{field}', this.$attrs.label);
        });
      }
      this.innerValue.value = parseFloat(value);
      this.$emit('input', this.innerValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}

.field {
  margin-bottom: 0;
}
.value-input {
  flex: 1 1 auto;
}
</style>
