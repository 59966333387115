
<div>
  <UploadMultipleControl
    :accept="accept"
    :horizontal="horizontal"
    :label="label"
    :get-signed-url="fileName => generateSignedUrl(fileName)"
    :verified="verified"
    :disabled="disabled"
    :message="message"
    :value="internal"
    @update:value="internal = $event"
  >
    <div class="control image-input">
      <ul class="placeholder">
        <li>TEST</li>
      </ul>
    </div>
  </UploadMultipleControl>
</div>
