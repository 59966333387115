<template>
  <b-navbar class="user-navbar" fixed-top>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="@/assets/images/main-logo.png" height="42" width="121" class="logo" />
      </b-navbar-item>
    </template>

    <template slot="start" v-if="contact.type === 'COMPANY'">
      <b-navbar-item :to="{ name: 'portal-claims' }" :active="isActiveRoute('portal-claims')" tag="router-link">
        <span :class="{ 'hidden-box': isActiveRoute('portal-claims') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/claims.svg')" />
        </span>
        <span :class="{ 'hidden-box': !isActiveRoute('portal-claims') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/claims_filled.svg')" />
        </span>
        {{ $t('home.claims') }}
      </b-navbar-item>
      <b-navbar-item :to="{ name: 'portal-claimants' }" :active="isActiveRoute('portal-claimants')" tag="router-link">
        <span :class="{ 'hidden-box': isActiveRoute('portal-claimants') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/contacts.svg')" />
        </span>
        <span :class="{ 'hidden-box': !isActiveRoute('portal-claimants') }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/contacts_filled.svg')" />
        </span>
        {{ $t('home.claimants') }}
      </b-navbar-item>
    </template>

    <template slot="end">
      <span v-if="contact.lastname" class="navbar-item is-mobile is-hidden-desktop">{{ contact.firstname + ' ' + contact.lastname }}</span>
      <!-- <b-navbar-item tag="router-link" :to="{ name: 'notifications' }"><img src="@/assets/icons/navbar/notification.svg"/></b-navbar-item> -->
      <b-navbar-dropdown v-if="user" :label="initials" :arrowless="true" class="navbar-user-dropdown">
        <b-navbar-item v-if="user && user.global_role === 'ADMIN' || user.global_role === 'CASETYPE_USER'" tag="router-link" :to="{ name: 'admin' }">
          {{ $t('navbar.backoffice') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'settings' }">{{ $t('navbar.settings') }}</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'faq' }">{{ $t('navbar.faq') }}</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'terms' }">{{ $t('navbar.terms') }}</b-navbar-item>
        <b-navbar-item :href="'mailto:' + CONTACT_MAIL">Contact us</b-navbar-item>
        <b-navbar-item v-if="hasRole(['ADMIN'])" tag="router-link" :to="{ name: 'platforminfo' }">Platform Info</b-navbar-item>
        <b-navbar-item @click="logout">{{ $t('navbar.logOut') }}</b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item v-else>
        <router-link tsssag="router-link" :to="{ name: 'login' }">Login</router-link>
        <LangNavbar />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';
import LangNavbar from '@/layout/LangNavbar.vue';
import store from '@/store/store';

export default {
  data() {
    return {
      CONTACT_MAIL: import.meta.env.VITE_CONTACT_MAIL
    };
  },
  components: {
    LangNavbar
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user'
    }),
    initials() {
      if (this.user && this.user.contact && this.user.contact.firstname && this.user.contact.lastname) {
        return (this.user.contact.firstname.substr(0, 1) + this.user.contact.lastname.substr(0, 1)).toUpperCase();
      } else if (this.user && this.user.contact && this.user.contact.name) {
        return this.user.contact.name.substr(0, 2).toUpperCase();
      } else {
        return '?';
      }
    },
    contact() {
      if (this.user && this.user.contact) {
        return this.user.contact;
      }

      return {};
    }
  },

  methods: {
    hasRole(roles) {
      return roles.indexOf(store.getters['authentication/global_role']) > -1
    },
    logout() {
      this.$store.dispatch('authentication/logout');
    },
    isActiveRoute(routeNames) {
      if (!Array.isArray(routeNames)) {
        routeNames = [routeNames];
      }
      return !!this.$route.matched.find(route => routeNames.find(name => name === route.name || (route.meta && route.meta.navbar === name)));
    }
  }
};
</script>
