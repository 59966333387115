import http from '../utils/http';

export default {
  async login(email, password) {
    const data = {
      email,
      password
    };

    return http({
      url: '/auth/local/login',
      method: 'post',
      data
    });
  },

  async requestMagicLink(email) {
    return http({
      url: '/api/magic-link/request',
      method: 'post',
      data: { email }
    });
  },

  async newMagicLink(contactId) {
    return http({
      url: '/api/magic-link/new',
      method: 'post',
      data: { contactId }
    });
  },

  async loginByMagicLink(token) {
    return http({
      url: '/auth/magic-link/login',
      method: 'post',
      data: { token }
    });
  },

  async loginByToken(token) {
    const data = {
      token
    };

    return http({
      url: '/auth/local/login',
      method: 'post',
      data
    });
  },

  async signup(firstname, lastname, email, password, agree_to_tc_pp, language, whitelabel_id, captcha) {
    const data = {
      firstname,
      lastname,
      email,
      password,
      agree_to_tc_pp,
      language,
      whitelabel_id,
      captcha
    };
    return http({
      url: '/auth/local/signup',
      method: 'post',
      data
    });
  },

  async logout() {
    return http({
      url: '/logout/',
      method: 'post'
    });
  },

  async confirmEmail(token) {
    return http({
      url: '/auth/local/confirm-email',
      method: 'post',
      data: { token }
    });
  },

  async resetPasswordRequest(email, whitelabel_id) {
    return http({
      url: '/auth/local/reset-password',
      method: 'post',
      data: { email, whitelabel_id }
    });
  },
  async resetPassword({ token, password, confirm_password }) {
    return http({
      url: `/auth/local/reset-password/${token}`,
      method: 'post',
      data: { password, confirm_password }
    });
  },
  async acceptInvite({ token, password, confirm_password, language, agree_to_tc_pp }) {
    return http({
      url: `/auth/local/accept-invite/${token}`,
      method: 'post',
      data: { password, confirm_password, language, agree_to_tc_pp }
    });
  },
  async passwordReset({ email, old_password, password, confirm_password }) {
    return http({
      url: '/auth/local/reset-password',
      method: 'post',
      data: { email, old_password, password, confirm_password }
    });
  },

  async captchaRequest(form) {
    return http({
      url: '/auth/captcha',
      method: 'get',
      params: { form },
      hide_errors: true
    });
  }
};
