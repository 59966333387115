import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from '@/layout/Layout.vue';
import RouterView from '@/layout/RouterView.vue';
import AdminNoSidebarLayout from '@/layout/AdminNoSidebarLayout.vue';
import store from '@/store/store';
import CaseService from '@/services/CaseService';
import FrontendService from '@/services/FrontendService';

Vue.use(VueRouter);

const LAYOUT_DEFAULT = 'DefaultLayout';
const LAYOUT_AUTH = 'AuthLayout';
const LAYOUT_ADMIN = 'AdminLayout';

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['authentication/isAuthenticated']) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (!store.getters['authentication/isAuthenticated']) {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath
      }
    });
    return;
  }
  next();
};

const hasRole = (roles) => {
  return (to, from, next) => {
    if (!store.getters['authentication/isAuthenticated']) {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      });
      return;
    }

    if (store.getters['authentication/isAuthenticated'] && roles.indexOf(store.getters['authentication/global_role']) > -1) {
      next();
      return;
    }
    next('/unauthorized');
  };
}

const ifIsAdmin = (to, from, next) => {
  if (!store.getters['authentication/isAuthenticated']) {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath
      }
    });
    return;
  }

  if (store.getters['authentication/isAuthenticated'] && store.getters['authentication/isAdmin']) {
    next();
    return;
  }
  next('/unauthorized');
};

const router = new VueRouter({
  mode: ['/index.html', '/app.html'].indexOf(window.location.pathname) > -1 ? 'hash' : 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/test-error',
      name: 'test-error',
      meta: { lang_navbar: true, layout: LAYOUT_DEFAULT, fullScreen: true },
      beforeEnter() {
        throw new Error('test-error');
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../modules/authentication/views/Login.vue'),
      meta: {
        lang_navbar: true,
        layout: LAYOUT_DEFAULT,
        fullScreen: true,
        background: 'landing'
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/magic-link/:token',
      name: 'magic-link-login',
      component: () => import('../modules/authentication/views/MagicLink.vue'),
      meta: {
        lang_navbar: true,
        layout: LAYOUT_DEFAULT,
        fullScreen: true,
        background: 'landing'
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('../modules/authentication/views/Signup.vue'),
      meta: {
        lang_navbar: true,
        layout: LAYOUT_DEFAULT,
        fullScreen: true,
        background: 'landing'
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      component: () => import('../modules/authentication/views/ConfirmEmail.vue'),
      meta: {
        lang_navbar: true,
        layout: LAYOUT_DEFAULT,
        fullScreen: true,
        background: 'landing'
      }
    },
    {
      path: '/reset-password-request',
      name: 'reset-password-request',
      component: () => import('../modules/authentication/views/ResetPasswordRequest.vue'),
      meta: {
        lang_navbar: true,
        layout: LAYOUT_DEFAULT,
        fullScreen: true,
        background: 'landing'
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('../modules/authentication/views/ResetPassword.vue'),
      meta: {
        lang_navbar: true,
        layout: LAYOUT_DEFAULT,
        fullScreen: true,
        background: 'landing'
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/invite',
      name: 'invite',
      component: () => import('../modules/authentication/views/Invite.vue'),
      meta: {
        lang_navbar: true,
        layout: LAYOUT_DEFAULT,
        fullScreen: true,
        background: 'landing'
      }
    },
    /*{
      path: "/error",
      name: "error",
      component: ErrorView,
      meta: { layout: LAYOUT_DEFAULT }
    } */
    {
      path: '/whitelabel_landing',
      name: 'whitelabel_landing',
      meta: {
        lang_navbar: true,
        layout: LAYOUT_DEFAULT,
        fullScreen: true,
        background: 'landing'
      },
      async beforeEnter(to, from, next) {
        const whitelabel = store.state.whitelabel.whitelabel;
        if (whitelabel?.id && whitelabel.theme?.landing_config?.skip_landing === 'landing_standard') {
          try {
            const response = await FrontendService.getCaseType(whitelabel.casetype_id);
            if (response) {
              next();
              return;
            }
          } catch (err) {
            next({ name: '/' });
          }
        }
        next('/');
      },
      component: () => import('../modules/home/views/WhiteLabelLanding.vue'),
    },
    {
      path: '',
      component: Layout,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('../modules/home/views/Home.vue'),
          meta: {
            background: 'landing'
          },
          async beforeEnter(to, from, next) {
            const whitelabel = store.state.whitelabel.whitelabel;
            if (whitelabel?.id) {
              if (whitelabel.theme?.landing_config?.skip_landing === 'landing_standard' && !store.getters['authentication/isAuthenticated']) {
                try {
                  const response = await FrontendService.getCaseType(whitelabel.casetype_id);
                  if (response) {
                    next({ name: 'whitelabel_landing' });
                    return;
                  }
                } catch (err) {
                  console.error(err);
                }
              } else
              if (whitelabel.theme?.landing_config?.skip_landing === 'goto_intake') {
                if (store.getters['authentication/isAuthenticated']) {
                  const claimsParams = {
                    _pageSize: 99,
                    _page: 1
                  };
                  const claimsResponse = await CaseService.getMyClaims(claimsParams);
                  const claimsPage = claimsResponse.data;
                  const openClaims = claimsPage.data;
                  if (openClaims.length > 0) {
                    return ifAuthenticated(to, from, next);
                  }
                }

                if (whitelabel.casetype_id) {
                  const casetype_id = whitelabel.casetype_id;
                  await store.dispatch('intakeng/resetIntake', { casetype_id: casetype_id, case_id: 'new' });
                  next({ name: 'intakeng', params: { casetype: casetype_id, case: 'new' } });
                  return;
                }
              }
              if (!whitelabel.theme?.landing_config?.require_auth) {
                next();
                return;
              }
            }

            return ifAuthenticated(to, from, next);
          }
        },
        {
          name: 'settings',
          path: 'settings',
          component: () => import('../modules/home/views/ViewSettings.vue'),
          meta: {
            background: 'landing'
          },
          beforeEnter: ifAuthenticated
        },
        { //
          name: 'platforminfo',
          path: 'platforminfo',
          component: () => import('../modules/platform/views/PlatformInfo.vue'),
          beforeEnter: hasRole(['ADMIN']),
        },
        {
          name: 'faq',
          path: 'faq',
          component: () => import('../modules/home/views/FaqView.vue'),
          meta: {
            background: 'landing'
          }
        },
        {
          name: 'terms',
          path: 'terms',
          component: () => import('../modules/home/views/TermsView.vue'),
          meta: {
            background: 'landing'
          }
        },
        {
          name: 'imprint',
          path: 'imprint',
          meta: {
            background: 'landing'
          },
          component: () => import('../modules/home/views/ImprintView.vue')
        },
        {
          name: 'privacy',
          path: 'privacy',
          meta: {
            background: 'landing'
          },
          component: () => import('../modules/home/views/PrivacyView.vue')
        },
        {
          path: '/case/:casetypeId',
          component: Layout,
          // beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'portal-case',
              path: '',
              meta: {
                background: 'landing'
              },
              component: () => import('../modules/home/views/CaseView.vue')
            },
            {
              name: 'portal-case-news',
              path: 'news/:newsId',
              meta: {
                background: 'landing'
              },
              component: () => import('../modules/home/views/CaseViewNews.vue')
            }
          ]
        },
        {
          path: '/claim/:claimId',
          name: 'portal-claim',
          component: () => import('../modules/home/views/ClaimView.vue'),
          meta: {
            background: 'landing'
          },
          beforeEnter: ifAuthenticated
        },
        {
          path: '/claim/:claimId/review',
          name: 'portal-claim-review',
          component: () => import('../modules/intakeng/views/IntakeReview.vue'),
          meta: {
            background: 'landing'
          },
          beforeEnter: ifAuthenticated
        },
        {
          path: '/claim/:claimId/sign-contract/:contractId',
          name: 'portal-sign',
          component: () => import('../modules/intakeng/views/SignContract.vue'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/claim/:claimId/review-contract/:contractId',
          name: 'portal-review-contract',
          component: () => import('../modules/home/views/ReviewContract.vue'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/claim/:claimId/additional_request',
          name: 'additional-request',
          component: () => import('../modules/intakeng/views/AdditionalRequest.vue')
        },
        {
          path: '/claim',
          name: 'portal-claims',
          component: () => import('../modules/home/views/UserListCases.vue'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/claimants',
          name: 'portal-claimants',
          component: () => import('../modules/home/views/UserListClaimants.vue'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/intake/claim-data/:casetype/:case',
          name: 'intake-claim-data',
          component: () => import('../modules/intakeng/views/IntakeClaimData.vue'),
          beforeEnter: ifAuthenticated
        }
      ]
    },
    {
      path: '/intake/:casetype/:case',
      name: 'intakeng',
      component: () => import('../modules/intakeng/views/IntakeWizard.vue'),
      meta: {
        layout: LAYOUT_DEFAULT,
        background: 'intake'
      },
      async beforeEnter(to, from, next) {
        const whitelabel = store.state.whitelabel.whitelabel;
        if (whitelabel?.id) {
          if (!whitelabel.theme?.intake_config?.require_auth) {
            await store.dispatch('intakeng/routeChange', { casetype_id: to.params.casetype, case_id: to.params.case });
            next();
            return;
          }
        }

        if (!store.getters['authentication/isAuthenticated']) {
          next({
            name: 'login',
            query: {
              redirect: to.fullPath
            }
          });
          return;
        }

        await store.dispatch('intakeng/routeChange', { casetype_id: to.params.casetype, case_id: to.params.case });
        next();
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminNoSidebarLayout,
      redirect: '/admin/cases',
      meta: { layout: LAYOUT_ADMIN },
      beforeEnter: hasRole(['ADMIN', 'CASETYPE_USER']),
      children: [
        // {
        //   name: 'dashboard',
        //   path: 'dashboard',
        //   component: () => import('../modules/general', 'Dashboard'),
        //   meta: { layout: LAYOUT_ADMIN }
        // },
        {
          name: 'auditlog',
          path: 'audit-log',
          component: () => import('../modules/audit-log/views/ListAuditLog.vue')
        },
        {
          name: 'notifications',
          path: 'notifications',
          component: () => import('../modules/home/views/ViewNotifications.vue')
        },
        {
          path: 'cases',
          component: RouterView,
          children: [
            {
              name: 'casetypes',
              path: '',
              component: () => import('../modules/casetype/views/ListCaseTypes.vue'),
              meta: {
                navbar: 'casetypes'
              }
            },
            {
              name: 'newcasetype',
              path: 'new',
              component: () => import('../modules/casetype/views/CreateCaseType.vue'),
              meta: {
                navbar: 'casetypes'
              }
            },
            {
              name: 'editcasetype',
              path: ':id/edit',
              component: () => import('../modules/casetype/views/EditCaseType.vue'),
              meta: {
                navbar: 'casetypes'
              }
            },
            {
              name: 'docgen-config',
              path: ':casetype_id/docgen/:id',
              component: () => import('../modules/casetype/views/DocgenConfig.vue'),
              meta: {
                navbar: 'casetypes'
              }
            },
            {
              name: 'casetype',
              path: ':id',
              component: () => import('../modules/casetype/views/ViewCaseType.vue'),
              meta: {
                navbar: 'casetypes'
              }
            }
          ]
        },
        {
          path: 'news',
          component: RouterView,
          children: [
            {
              name: 'newsitems',
              path: '',
              component: () => import('../modules/news/views/ListNews.vue'),
              meta: {
                navbar: 'casetypes'
              }
            },
            {
              name: 'newnewsitem',
              path: 'new',
              component: () => import('../modules/news/views/CreateNews.vue'),
              meta: {
                navbar: 'casetypes'
              }
            },
            {
              name: 'newsitem',
              path: ':id',
              component: () => import('../modules/news/views/ViewNews.vue'),
              meta: {
                navbar: 'casetypes'
              }
            }
          ]
        },
        {
          path: 'claims',
          component: RouterView,
          children: [
            {
              name: 'cases',
              path: '',
              component: () => import('../modules/case/views/ListCases.vue'),
              meta: {
                navbar: 'cases'
              }
            },
            {
              name: 'newcase',
              path: 'new',
              component: () => import('../modules/case/views/CreateCase.vue'),
              meta: {
                navbar: 'cases'
              }
            },
            {
              path: '',
              component: RouterView,
              children: [
                {
                  name: 'case',
                  path: ':id',
                  component: () => import('../modules/case/views/ViewCase.vue'),
                  meta: {
                    navbar: 'cases'
                  }
                },
                {
                  name: 'backoffice-sign',
                  path: ':claimId/sign-contract/:contractId',
                  component: () => import('../modules/case/views/CounterSignContract.vue'),
                  meta: {
                    navbar: 'cases'
                  }
                },
                {
                  name: 'backoffice-contract-preview',
                  path: ':claimId/contract-preview/:contractId',
                  component: () => import('../modules/case/views/PreviewContract.vue'),
                  meta: {
                    navbar: 'cases'
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'case-field-data',
          path: 'claims/:id/data',
          component: () => import('../modules/case/views/EditFieldData.vue')
        },
        {
          path: 'contacts',
          component: RouterView,
          children: [
            {
              name: 'contacts',
              path: '',
              component: () => import('../modules/contact/views/ListContacts.vue'),
              meta: {
                navbar: 'contacts'
              }
            },
            {
              name: 'newcontact',
              path: 'new',
              component: () => import('../modules/contact/views/CreateContact.vue'),
              meta: {
                navbar: 'contacts'
              }
            },
            {
              name: 'contact',
              path: ':id',
              component: () => import('../modules/contact/views/ViewContact.vue'),
              meta: {
                navbar: 'contacts'
              }
            }
          ]
        },
        {
          path: 'users',
          component: RouterView,
          children: [
            {
              name: 'users',
              path: '',
              component: () => import('../modules/user/views/ListUsers.vue')
            },
            {
              name: 'newuser',
              path: 'new',
              component: () => import('../modules/user/views/CreateUser.vue')
            },
            {
              name: 'user',
              path: ':id',
              component: () => import('../modules/user/views/ViewUser.vue')
            }
          ]
        },
        {
          path: 'whitelabels',
          component: RouterView,
          children: [
            {
              name: 'whitelabels',
              path: '',
              component: () => import('../modules/whitelabel/views/ListWhiteLabels.vue')
            },
            {
              name: 'newwhitelabel',
              path: 'new',
              component: () => import('../modules/whitelabel/views/CreateWhiteLabel.vue')
            },
            {
              name: 'whitelabel',
              path: ':id',
              component: () => import('../modules/whitelabel/views/ViewWhiteLabel.vue')
            }
          ]
        }
      ]
    },
    {
      name: 'not-matched',
      path: '*',
      component: () => import('../shared/NotFoundView.vue'),
      meta: { layout: LAYOUT_ADMIN }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.query.token && !store.state.authentication.accessToken && to.name !== 'confirm-email') {
    await store.dispatch('authentication/login', { token: to.query.token });
  }

  next();
});

router.afterEach(to => {
  if (to.meta.background) {
    document.body.setAttribute('data-background', to.meta.background);
  } else {
    document.body.removeAttribute('data-background');
  }
});

store.subscribe(async (mutation, state) => {
  switch (mutation.type) {
    case 'intakeng/setSavedClaim':
      {
        const oldId = state.intakeng.claim.id;
        const claim = mutation.payload;

        if (oldId !== claim.id) {
          await router.replace({
            name: 'intakeng',
            params: {
              casetype: claim.casetype_id,
              case: claim.id
            }
          });
        }
      }
      break;
  }
});

export default router;
