const CaseTypeStatus = {
  REGISTRATION_ONLY: 'Registration only', // <-- '0_registration_only'
  REGISTRATION_PROCESS: 'Registration process', // <-- '1_registration_process'
  LITIGATION_PENDING: 'Litigation Pending', // <-- '2_litigation_pending'
  SETTLEMENT_PENDING: 'Settlement Pending', //<-- '3_settlement_pending'
  NOT_LAUNCHED: 'Not launched', // <-- '4_not_launched'
  CLOSED: 'Closed', // <-- '5_closed'
  SETTLED: 'Settled' // <-- '6_settled'
};

const CaseType = {
  RETAIL: 'Retail',
  INSTITUTIONAL: 'Institutional'
};

const CaseStatus = {
  DRAFT: 'In progress',
  ABANDONED: 'Abandoned',
  // CONTRACT_PENDING: 'Contract pending',
  // CONTRACT_SIGNED_BY_CLAIMANT: 'Contract signed by claimant',
  // CONTRACT_APPROVED: 'Contract approved',
  INTAKE_COMPLETE: 'Analysis ongoing',
  APPROVED: 'Complete',
  REJECTED: 'Rejected',
  FROZEN: 'Frozen',
  ARCHIVED: 'Archived'
};

const ContractStatus = {
  GENERATING: 'Generating',
  UNSIGNED: 'Unsigned',
  SIGNED_BY_CLAIMANT: 'Signed',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
};

const UserEnabled = {
  true: 'Yes',
  false: 'No'
};

const ContactType = {
  PERSON: 'Person',
  COMPANY: 'Company'
};

const CommercialType = {
  RETAIL: 'Retail',
  INSTITUTIONAL: 'Institutional'
};

const CaseRelationType = {
  CLAIMANT: 'Claimant',
  REPRESENTS: 'Represented by',
  CONTACT: 'Contact',
  REGISTRAR: 'Registrar'
};

const PaymentMethod = {
  BANK: 'Bank Transfer',
  ONLINE: 'Online'
};

const FieldType = {
  TEXT: 'Text',
  NUMBER: 'Number',
  DATE: 'Date',
  CURRENCY: 'Currency',
  CHECKBOX: 'Checkbox',
  RADIO: 'Radio',
  LIST: 'List',
  MULTILINE_TEXT: 'Multiline Text',
  CONSENT: 'Consent',
  UPLOAD: 'Upload',
  UPLOAD_MULTIPLE: 'Upload multiple',
  UPLOAD_SIGNED: 'Upload signed',
  COLLECTION: 'Collection'
};

const FieldVisibility = {
  VISIBLE: 'Visible',
  READ_ONLY: 'Read Only',
  HIDDEN: 'Hidden'
};

const AcceptTypes = {
  '': 'Any file type',
  'image/*': 'Only images',
  'image/*,application/pdf': 'Images and PDF'
};

const LanguageMap = {
  en: 'English',
  nl: 'Dutch',
  fr: 'French',
  de: 'German',
  es: 'Spanish',
  it: 'Italian'
};

const RoleMap = {
  USER: 'User',
  ADMIN: 'Admin',
  CASETYPE_USER: 'Case Manager'
};

const PERSON_FIELDS = ['firstname', 'lastname', 'email', 'address', 'postal_code', 'city', 'country', 'primary_phone'];
const COMPANY_FIELDS = ['name', 'vat_number', 'address', 'postal_code', 'city', 'country'];
const CONTACTPERSON_FIELDS = ['firstname', 'lastname', 'email'];

const PERSON_DOCUMENTS_TEMPLATE = [
  {
    type: 'document',
    label: 'ID Card',
    description: 'Upload ID card front and back side pictures for this claimant. Please make sure that the picture is in good quality.',
    files: [
      { label: 'Front-Side', file: null, url: '' },
      { label: 'Back-Side', file: null, url: '' }
    ]
  }
];
const COMPANY_DOCUMENTS_TEMPLATE = [
  {
    type: 'document',
    label: 'Statutes Of Incorporation',
    description: 'Upload Statute Of Incorporation document of this company.',
    files: [{ label: 'Statutes Of Incorporation', file: null, url: '' }]
  },
  {
    type: 'document',
    label: 'KBO Uittreksel',
    description: 'Upload KBO Uitreksel document of this company.',
    files: [{ label: 'KBO Uittreksel', file: null, url: '' }]
  }
];

const NOTIFICATION_TITLES = {
  'claim.created': 'Claim created',
  'claim.required_files_completed': 'Claim required files completed',
  'claim.investor_added': 'Investor added',
  'claim.contract_digital_signed': 'Contract digital signed',
  'claim.contract_digital_confirmed': 'Contract digital confirmed',
  'claim.contract_file_submitted': 'Contract file submitted',
  'claim.assets_added': 'Assets added',
  'claim.created_by_institution': 'Claim created by institution',
  'claim.custom_fields_completed': 'Custom fields completed',
  'claim.intake_completed': 'Analysis ongoing',
  'contact.address_changed': 'Address changed'
};

const CURRENCIES = [
  { code: 'EUR', symbol: '€' },
  { code: 'USD', symbol: '$' }
];

export const DEFAULT_LANDING_CONFIG = {
  skip_landing: '',
  require_auth: 'show',
}

export const DEFAULT_INTAKE_CONFIG = {
  require_auth: 'show',
  form_id: '',
  documents: '',
  dynamic_data: '',
  company_representative: ''
};

export default {
  CaseTypeStatus,
  CaseType,
  UserEnabled,
  CaseStatus,
  ContactType,
  CommercialType,
  ContractStatus,
  CaseRelationType,
  PaymentMethod,
  FieldType,
  FieldVisibility,
  AcceptTypes,
  LanguageMap,
  RoleMap,
  PERSON_FIELDS,
  CONTACTPERSON_FIELDS,
  COMPANY_FIELDS,
  PERSON_DOCUMENTS_TEMPLATE,
  COMPANY_DOCUMENTS_TEMPLATE,
  NOTIFICATION_TITLES,
  CURRENCIES
};
