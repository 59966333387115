<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules">
    <b-field
      :horizontal="horizontal"
      :class="{ 'field-vertical': !horizontal }"
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': verified }"
      class="field"
      :custom-class="verified ? 'is-verified' : ''"
    >
      <b-datepicker :horizontal="horizontal" ref="datepicker" v-model="innerValue" v-bind="$attrs" @focus="positionDropdown" :years-range="[-50, 50]" />
      <a v-if="hasDelete" class="clear-button" @click.prevent.stop="clearValue">
        <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/times.svg')" />
      </a>
    </b-field>
    <p class="help is-danger">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    hasDelete: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: null
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = typeof this.value === 'string' ? new Date(this.value) : this.value;
    }
  },
  methods: {
    positionDropdown() {
      const dropdownEl = this.$el.querySelector('.dropdown-content');
      if (!dropdownEl) {
        return;
      }

      const mainRect = this.$refs.datepicker.$el.getBoundingClientRect();

      const wheight = window.innerHeight;

      if (wheight - mainRect.bottom >= mainRect.top) {
        dropdownEl.style.top = mainRect.bottom + 'px';
        dropdownEl.style.bottom = 'initial';
      } else {
        dropdownEl.style.top = 'initial';
        dropdownEl.style.bottom = wheight - mainRect.top + 'px';
      }

      dropdownEl.style.left = mainRect.left + 'px';
      dropdownEl.style.minWidth = 'initial';
    },
    clearValue() {
      this.innerValue = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}

.field {
  margin-bottom: 0;
}

::v-deep .dropdown-content {
  position: fixed;
  /*left: 0;*/
  /*top: 0;*/
}

.field-vertical {
  position: relative;
  display: block;
}
.clear-button {
  position: absolute;
  cursor: pointer;
  color: #000;
  top: 36px;
  right: 10px;
  z-index: 99999;
}
</style>
