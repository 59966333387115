import Vue from 'vue';
import dayjs from 'dayjs';

import { configure, extend, setInteractionMode, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import i18n from '@/i18n/i18n';

configure({
  defaultMessage: (field, values) => {
    // values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  }
});

for (let name in rules) {
  extend(name, rules[name]);
}

extend('phone', {
  validate: () => true
});

extend('number', {
  validate: value => !isNaN(parseFloat(value)) && isFinite(value),
  message() {
    // You might want to generate a more complex message with this function.
    return i18n.t('validation.notANumber');
  }
});

extend('tc_accepted', {
  validate: value => value === true,
  message() {
    // You might want to generate a more complex message with this function.
    return i18n.t('validation.tc_accepted');
  }
});

// Ues the same function at backend
function passwordToError(password) {
  let i18nMessage = '';
  let i18nParam = null;

  if (password.length < 8) {
    i18nMessage = 'validation.passwordTooShort';
    i18nParam = { length: 8 };
  } else if (password.indexOf(' ') > -1) {
    i18nMessage = 'validation.passwordContainsSpace';
    // eslint-disable-next-line no-useless-escape
  } else if (!password.match(/[`~!@#\$%\^&\*\(\)\-=_+\\\[\]{}/\?,\.\<\>]/)) {
    i18nMessage = 'validation.passwordWithoutSpecial';
  } else if (!password.match(/[a-z]/)) {
    i18nMessage = 'validation.passwordWithoutLower';
  } else if (!password.match(/[A-Z]/)) {
    i18nMessage = 'validation.passwordWithoutUpper';
  } else if (!password.match(/[0-9]/)) {
    i18nMessage = 'validation.passwordWithoutDigit';
  }

  return {
    i18nMessage,
    i18nParam
  };
}

// Password requirements are: min 8 characters, 1 upper, 1 lower, 1 digit and 1 special character.
extend('password', {
  validate: value => !passwordToError(value).i18nMessage,
  message(field, values) {
    const error = passwordToError(values._value_);
    return i18n.t(error.i18nMessage, error.i18nParam);
  }
});

extend('not_in_past', {
  validate: value => {
    const dayStart = dayjs()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    return !dayjs(value).isBefore(dayStart);
  },
  message() {
    return i18n.t('validation.dateInPast');
  }
});
extend('not_in_future', {
  validate: value => {
    const dayEnd = dayjs()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    return !dayjs(value).isAfter(dayEnd);
  },
  message() {
    return i18n.t('validation.dateInFuture');
  }
});

extend('select_one', {
  validate: value => !!(!value || value.length === 0),
  message(field) {
    // You might want to generate a more complex message with this function.
    return `Please select a ${field}.`;
  }
});

extend('min_date', {
  validate: (value, params) => {
    if (params.length < 1 || !params[0]) return true;
    const min_date = params[0].substr(0, 10);
    const date = value.toISOString().substr(0, 10);
    return min_date <= date;
  },
  message(field, params) {
    return `Min date is ${params[0].substr(0, 10)}.`;
  }
});
extend('max_date', {
  validate: (value, params) => {
    if (params.length < 1 || !params[0]) return true;
    const max_date = params[0].substr(0, 10);
    const date = value.toISOString().substr(0, 10);
    return max_date >= date;
  },
  message(field, params) {
    return `Max date is ${params[0].substr(0, 10)}.`;
  }
});

setInteractionMode('eager');
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.prototype.$scrollToErrors = () => {
  const el = document.querySelector('.tabs .has-error a, .help.is-danger');
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }
};
