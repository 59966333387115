import CRUDService from './CRUDService';
import http from '../utils/http';

class CaseRelationDocumentService extends CRUDService {
  constructor(path) {
    super(path);
  }

  getSignedUrl(id, fileName) {
    return http({
      url: `api/${this.path}/${id}/sign`,
      method: 'post',
      data: { fileName }
    });
  }
}

export default new CaseRelationDocumentService('case-relation-documents');
