<template>
  <div class="modal-card" style="width: 600px">
    <header class="modal-card-head">
      <p v-if="!editMode" class="modal-card-title">Add Row</p>
      <p v-if="editMode" class="modal-card-title">Edit Row</p>
    </header>
    <section class="modal-card-body">
      <DynamicForm :disabled="disabled" :fields="fields" :entity="initialValue" :case-id="caseId" :on-submit="forwardSumbit" :on-cancel="onCancel"></DynamicForm>
    </section>
  </div>
</template>

<script>
import DynamicForm from './DynamicForm.vue';
export default {
  components: {
    DynamicForm
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    editEntity: {
      type: Object,
      default: null
    },
    caseId: {
      type: String,
      default: null
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    initialValue() {
      if (this.editMode) {
        return this.editEntity;
      } else {
        return {};
      }
    }
  },

  methods: {
    forwardSumbit(entity) {
      this.onSubmit(entity);
      this.$parent.close();
    },
    onCancel() {
      this.$parent.close();
    }
  }
};
</script>
