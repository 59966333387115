import router from '@/router';
import AuthService from '@/services/AuthService';

import i18n from '@/i18n/i18n';

const initialState = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  return user ? { status: { loggedIn: true }, user, accessToken, refreshToken } : { status: {}, user: null, accessToken: null, refreshToken: null };
};

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async loginSuccess({ dispatch, commit }, user) {
      commit('loginSuccess', user);
      await dispatch('i18n/changeLang', user.language, { root: true });
    },
    async login({ dispatch, commit }, { email, password, token, redirect }) {
      try {
        let response;

        if (email) {
          commit('loginRequest', { email });
          response = await AuthService.login(email, password);
          commit('loginSuccess', response.data);
          await dispatch('i18n/changeLang', response.data.language, { root: true });
        }

        if (token) {
          response = await AuthService.loginByToken(token);
          commit('loginSuccess', response.data);
          await dispatch('i18n/changeLang', response.data.language, { root: true });
          return true;
        }

        if (redirect) {
          router.push(redirect);
          return true;
        }

        if (response.data.global_role === 'ADMIN') {
          router.push({
            name: 'admin'
          });
        } else {
          router.push({
            name: 'home'
          });
        }
        return true;
      } catch (error) {
        commit('loginFailure', error);
        //dispatch('alert/error', error, { root: true });
        dispatch('alert/error', i18n.t(error), { root: true });
        return false;
      }
    },
    async requestMagicLink({ dispatch, commit }, { email }) {
      try {
        await AuthService.requestMagicLink(email);
        dispatch('alert/snackbar', { message: i18n.t('magicLink.emailSent'), duration: 10000 }, { root: true });
        return true;
      } catch (error) {
        dispatch('alert/error', i18n.t(error), { root: true });
        return false;
      }
    },
    async newMagicLink({ dispatch, commit }, { contactId }) {
      try {
        const result = await AuthService.newMagicLink(contactId);
        // dispatch('alert/snackbar', { message: i18n.t('magicLink.successGene'), duration: 10000 }, { root: true });
        return result.data;
      } catch (error) {
        dispatch('alert/error', i18n.t(error), { root: true });
        return false;
      }
    },
    async loginByMagicLink({ dispatch, commit }, { token }) {
      try {
        commit('loginRequest', { token });
        const response = await AuthService.loginByMagicLink(token);
        commit('loginSuccess', response.data);
        if (response.data.global_role === 'ADMIN') {
          await router.push({
            name: 'admin'
          });
        } else {
          await router.push({
            name: 'home'
          });
        }
        return true;
      } catch (error) {
        await router.push({
          name: 'login'
        });
        commit('loginFailure', error);
        dispatch('alert/error', i18n.t(error), { root: true });
        return false;
      }
    },
    async loginByToken({ commit }, token) {
      console.log('token');
    },
    async signup({ dispatch, commit, rootState }, { firstname, lastname, email, password, agree_to_tc_pp, language, captcha }) {
      try {
        await dispatch('i18n/changeLang', language, { root: true });
        const whitelabel_id = rootState.whitelabel.whitelabel_id;
        await AuthService.signup(firstname, lastname, email, password, agree_to_tc_pp, language, whitelabel_id, captcha);
        await dispatch(
          'alert/snackbar',
          {
            message: i18n.t('alert.weHaveSentConfirm'),
            duration: 10000
          },
          { root: true }
        );
        router.push('/login');
      } catch (error) {
        commit('loginFailure', error);
        dispatch('alert/error', error, { root: true });
      }
    },
    async confirmEmail({ dispatch, commit }, { token }) {
      try {
        await AuthService.confirmEmail(token);
        router.push('/login');
        dispatch('alert/snackbar', { message: i18n.t('alert.yourEmailHasBeenConfirmed'), duration: 10000 }, { root: true });
      } catch (error) {
        commit('Email confirmation failure', error);
        dispatch('alert/error', error, { root: true });
      }
    },
    async logout({ dispatch, commit }) {
      localStorage.setItem('accessToken', null);
      localStorage.setItem('refreshToken', null);
      localStorage.setItem('user', null);
      localStorage.setItem('authenticated', false);
      localStorage.clear();
      commit('logout');
      dispatch('resetAll', {}, { root: true });
      await router.push({ path: '/logout', query: { force_logout: Math.random() } });
      await router.push({ name: 'home' });
    },
    error() {
      router.push('/error');
    },
    // async refreshMe({ commit }) {
    //   const response = await MeApi.getMe();
    //   if (response.status === 200) {
    //     commit('setUser', response.data);
    //   }
    // },
    // async updateUser({ commit, dispatch }, { firstname, lastname }) {
    //   const response = await UserApi.updateUser({ firstname, lastname });
    //   if (response.status === 200) {
    //     dispatch('alert/snackbar', { message: 'User profile updated!' }, { root: true });
    //   }
    //   commit('setUser', response.data);
    // },
    async resetPasswordRequest({ rootState, dispatch }, { email }) {
      const whitelabel_id = rootState.whitelabel.whitelabel_id;
      const response = await AuthService.resetPasswordRequest(email, whitelabel_id);
      if (response.status === 200) {
        dispatch('alert/snackbar', { message: response.data.message, duration: 5000 }, { root: true });
      }
      router.push('login');
    },
    async resetPassword({ dispatch }, { token, password, confirm_password }) {
      const response = await AuthService.resetPassword({
        token,
        password,
        confirm_password
      });
      if (response.status === 200) {
        dispatch('alert/snackbar', { message: response.data.message }, { root: true });
      }
      router.push('login');
    },
    async acceptInvite({ dispatch }, { token, password, confirm_password, language, agree_to_tc_pp }) {
      const response = await AuthService.acceptInvite({
        token,
        password,
        confirm_password,
        language,
        agree_to_tc_pp
      });
      if (response.status === 200) {
        dispatch('alert/snackbar', { message: response.data.message }, { root: true });
      }
      router.push('login');
    },
    async passwordReset({ dispatch }, { email, old_password, password, confirm_password }) {
      const response = await AuthService.passwordReset({
        email,
        old_password,
        password,
        confirm_password
      });
      if (response.status === 200) {
        dispatch('alert/snackbar', { message: response.data.message }, { root: true });
      }
      router.push({
        name: 'profile'
      });
    }
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      // login successful if there's a jwt token in the response
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('accessToken', user.token);
      localStorage.setItem('refreshToken', user.refreshToken);
      localStorage.setItem('authenticated', true);

      state.user = user;
      state.accessToken = user.token;
      state.refreshToken = user.refreshToken;
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
    }
  },
  getters: {
    user(state) {
      return state.user;
    },
    global_role(state) {
      if (!state.user) return 'ANONYMOUS';
      return state.user.global_role;
    },
    isAuthenticated: state => !!state.accessToken,
    isAdmin: state => state.user && state.user.global_role === 'ADMIN'
  }
};
