import axios from 'axios';
import store from '../store/store';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import router from '../router';
import { get } from 'lodash';

// Set up axios
const service = axios.create({
  baseURL: `${window.sketenv.API_BASE_URL}`, // Base url injected from environment-config
  headers: {
    'Content-Type': 'application/json'
  },
  // paramsSerializer: params => qs.stringify(params),
  timeout: 120000 // Request timeout
});

service.interceptors.request.use(
  config => {
    // If it doesn't start with http it means is our api, so add the authorization header
    if (!config.url.startsWith('http') && localStorage.getItem('accessToken')) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.request.use(
  config => {
    // If it doesn't start with http it means is our api, so add the authorization header
    if (!config.url.startsWith('http')) {
      const lang = store.state.i18n.lang || 'en';
      config.headers['locale'] = lang;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Show and error in the UI when a request fails
// service.interceptors.response.use(
//   response => response,
//   error => {
//
//     Message({
//       message: error.message,
//       type: 'error',
//       duration: 5 * 1000
//     });
//
//     return Promise.reject(error);
//   }
// );

async function http(props) {
  try {
    const response = await service(props);
    if (response.headers['access_token'] && !store.getters['authentication/isAuthenticated']) {
      await store.dispatch('authentication/login', { token: response.headers['access_token'] });
    }
    return response;
  } catch (error) {
    const configError = error.config ? ' (' + error.config.method.toUpperCase() + ' ' + error.config.url + ')' : '';
    const unknownErrorMessage = error.message ? error.message + configError : 'Unknown Error: ' + error.toString();
    let message = unknownErrorMessage;
    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
      message = error.response.data.error.message;
    } else {
      console.error(error);
    }

    // Unauthorized: jwt expired
    if (message === 'jwt expired' || message === 'jwt malformed') {
      try {
        const res = await service({
          url: '/auth/local/token-refresh',
          headers: {
            'refresh-token': localStorage.getItem('refreshToken')
          },
          method: 'get'
        });
        await store.dispatch('authentication/loginSuccess', res.data);
        if (localStorage.getItem('accessToken')) {
          error.response.config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
        }
        return await axios(error.response.config);
      } catch (err) {
        store.dispatch('authentication/logout');
        if (!props.hide_errors) {
          Snackbar.open({
            message: 'Your session has timed out',
            type: 'is-error',
            position: 'is-top',
            actionText: null,
            duration: 2000
          });
        }
      }
    }

    if (error.response && error.response.status === 404) {
      if (!props.ignore_404) {
        router.push('/error');
        return;
      }
    }
    if (error.response && error.response.status === 401) {
      // store.dispatch('authentication/logout');
      // throw new Error(message);
      if (!props.hide_errors) {
        Snackbar.open({
          message: 'Unauthorized Request. The page did not load correctly.',
          type: 'is-error',
          position: 'is-top',
          actionText: null,
          duration: 2000
        });
      }
      throw new Error(message);
    }

    let errorMessage = get(
      error,
      ['response', 'data', 'message'],
      get(error, ['response', 'data', 'stack', 'message'], get(error, ['response', 'data', 'error', 'message'], get(error, ['message'], 'Something went wrong, please try again.')))
    );
    if (error.response && error.response.data) {
      const data = error.response.data;
      if (data.error && data.error.message) {
        errorMessage = error.response.data.error.message;
      }
    }

    const throwError = new Error(errorMessage);
    if (message === 'Validation error') {
      if (error.response.data.error.data) {
        throwError.validateErrors = error.response.data.error.data;
      }
    }

    if (!props.hide_errors) {
      Snackbar.open({
        message,
        type: 'is-error',
        position: 'is-top',
        actionText: null,
        duration: 5000
      });
    }
    throw throwError;
  }
}

export default http;
