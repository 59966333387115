/* eslint-disable no-console */
function getEnvironmentConfig() {
  window.sketenv = {
    GIT_SHA: import.meta.env.VITE_GIT_SHA || import.meta.env.GIT_SHA,
    BUILD_TIME: import.meta.env.VITE_BUILD_TIME || import.meta.env.BUILD_TIME,
    VERSION: import.meta.env.VITE_VERSION || import.meta.env.VERSION
  };

  const ENVIRONMENT_NAME = document.querySelector('meta[name="environment_name"]')?.getAttribute('content') || null;
  const hostname = window && window.location && window.location.hostname;

  console.log(import.meta);

  if (/^.*staging.mydeminor.com/.test(hostname) || ENVIRONMENT_NAME === 'staging') {
    console.info('Detected staging environment', window.sketenv);
    window.sketenv.API_BASE_URL = 'https://api-staging.mydeminor.com/';
    window.sketenv.VITE_ENVIRONMENT_NAME = 'staging';
  } else if (/^.*mydeminor.com/.test(hostname) || ENVIRONMENT_NAME === 'prod') {
    console.info('Detected production environment', window.sketenv);
    window.sketenv.API_BASE_URL = 'https://api-prod.mydeminor.com/';
    window.sketenv.VITE_ENVIRONMENT_NAME = 'prod';
  } else if ((/makemeweb\.dev$/.test(hostname) || ENVIRONMENT_NAME === 'dev') || import.meta.env.SPADE === "true") {
    console.info('Detected dev environment', window.sketenv);
    window.sketenv.API_BASE_URL = 'https://api.deminor.makemeweb.dev/';
    window.sketenv.VITE_ENVIRONMENT_NAME = 'dev';
  } else {
    window.sketenv.API_BASE_URL = 'http://0.0.0.0:8848/';
    console.info('Using configurable environment', window.sketenv);
  }

  return window.sketenv;
}

export default getEnvironmentConfig();
