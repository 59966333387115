
<div>
  <div v-if="documentToBeSigned">
    <div class="field" :class="{ 'is-horizontal': horizontal }">
      <div :class="{ 'field-label': horizontal }">
        <label class="label">{{ $t('signDocument.downloadAndSign') }}</label>
      </div>
      <div v-if="!disabled" :class="{ 'field-body': horizontal }">
        <div class="control image-input">
          <div class="placeholder">
            <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/download.svg')" style="margin-right: 10px;" />
            <a data-testid="document-to-be-signed" @click.prevent.stop="goToS3(documentToBeSigned, getFilename(documentToBeSigned))">{{ getFilename(documentToBeSigned) }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <UploadControl
    :accept="accept"
    :horizontal="horizontal"
    :label="label"
    :get-signed-url="fileName => generateSignedUrl(fileName)"
    :verified="verified"
    :disabled="disabled"
    :message="message"
    :value="internal"
    @update:value="internal = $event"
  />
</div>
