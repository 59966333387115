
<ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules">
  <b-field
    :horizontal="horizontal"
    :class="{ 'field-vertical': !horizontal }"
    v-bind="$attrs"
    :type="{ 'is-danger': errors[0], 'is-success': verified }"
    class="field"
    :custom-class="verified ? 'is-verified' : ''"
  >
    <b-datepicker :horizontal="horizontal" ref="datepicker" v-model="innerValue" v-bind="$attrs" @focus="positionDropdown" :years-range="[-50, 50]" />
    <a v-if="hasDelete" class="clear-button" @click.prevent.stop="clearValue">
      <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/times.svg')" />
    </a>
  </b-field>
  <p class="help is-danger">{{ errors[0] }}</p>
</ValidationProvider>
