const initialState = () => ({
  whitelabel_id: null,
  whitelabel: null
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  actions: {
    changeWhiteLabel({ commit }, whitelabel) {
      commit('setWhiteLabel', whitelabel);
    }
  },
  mutations: {
    setWhiteLabel(state, whitelabel) {
      state.whitelabel = whitelabel;
      state.whitelabel_id = whitelabel.id;
    }
  }
};
