
<div class="has-text-weight-semibold">
  <span v-if="type === 'CURRENCY'">{{ value && value.name && value.value ? `${CURRENCIES[value.name].symbol}${value.value}` : '-' }}</span>
  <span v-else-if="type === 'DATE'">{{ value | formatDate }}</span>
  <span v-else-if="type === 'UPLOAD'"
    ><a @click="goToS3(value)">{{ getFileName(value) }}</a></span
  >
  <span v-else-if="type === 'UPLOAD_MULTIPLE'">
    <span v-for="(upload, idx) in value" :key="idx">
      <a @click="goToS3(upload.url, upload)">{{ upload.legacy_name || getFileName(upload.url) }}</a> <span>{{ upload.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span
      ><br v-if="idx < value.length - 1" />
    </span>
  </span>
  <span v-else-if="type === 'UPLOAD_SIGNED'"
    ><span v-if="value && value.document_to_be_signed"
      >Document to sign: <a @click="goToS3(value.document_to_be_signed)">{{ getFileName(value.document_to_be_signed) }}</a></span
    >
    -
    <span v-if="value && value.value"
      >Uploaded document: <a @click="goToS3(value.value)">{{ getFileName(value.value) }}</a></span
    ></span
  >
  <span v-else-if="type === 'RADIO'">{{ localizeOption(value) }}</span>
  <span v-else-if="type === 'CHECKBOX'">{{ value ? value.map(item => localizeOption(item)).join(', ') : '' }}</span>
  <span v-else-if="type === 'CONSENT'">
    <span v-if="value">{{ value.consented_by }} consented at {{ value.consented_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
    <span v-else>No response received yet</span>
  </span>
  <span v-else class="has-text-weight-semibold">{{ value }}</span>
</div>
