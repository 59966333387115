import { DEFAULT_INTAKE_CONFIG } from '@/utils/enums';

const STEPS = [
  { name: 'general-info', label: ('intake.generalInfo'), component: 'StepGeneralInfo' },
  { name: 'claimant-info', label: ('intake.claimantInfo'), component: 'StepClaimantInfo' },
  { name: 'documents', label: ('intake.documents'), component: 'StepDocuments' },
  { name: 'dynamic-data', label: ('intake.dynamicData'), component: 'StepDynamicData' },
  { name: 'review-and-submit', label: ('intake.reviewAndSubmit'), component: 'StepReviewAndSubmit' },
  { name: 'sign-agreement', label: ('intake.signAgreement'), component: 'StepSignAgreement' }
];

const INTAKE_CONFIG = {
  require_login: true
};

const initialState = () => {
  return {
    step: 0,
    steps: STEPS,
    intake_config: INTAKE_CONFIG
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async calculateCurrentStep({ state, commit }, completed_steps) {
      let no = 0;
      for (const step of state.steps) {
        if (completed_steps[step.name]) {
          no++;
        } else {
          break;
        }
      }
      await commit('setStepNo', no);
    },
    async reset({ commit }, { is_contract_enabled, has_fields, whitelabel }) {
      await commit('setStepNo', 0);
      await commit('setStepsEnabled', { is_contract_enabled, has_fields, whitelabel });
    },
    async previous({ state, dispatch, commit }) {
      if (state.step - 1 < state.steps.length) {
        await commit('setStepNo', state.step - 1);
      }
      if (state.step === 0) {
        await dispatch('intakeng/setCaseMeta', {
          completed_steps: {}
        });
      }
    },
    async next({ rootState, state, dispatch, commit }) {
      const claim = rootState.intakeng.claim;
      const currentStepName = state.steps[state.step].name;
      const completedSteps = Object.assign({}, claim.intake_meta.completed_steps, { [currentStepName]: true });
      if (state.step + 1 < state.steps.length) {
        const nextStepName = state.steps[state.step + 1].name;
        await commit('setStepNo', state.step + 1);
        await dispatch('intakeng/setCaseMeta', { current_step: nextStepName, completed_steps: completedSteps }, { root: true });
        // this.$router.push({ name: 'intakeng-step', params: { step: nextStepName } });
        return true;
      } else {
        await dispatch('intakeng/setCaseMeta', { completed_steps: completedSteps }, { root: true });
        return false;
        // await dispatch('intakeng/save', null, { root: true });
        // await this.$router.push({ name: 'portal-claim', params: { claimId: claim.id } });
      }
    }
  },
  mutations: {
    setStepsEnabled(state, { is_contract_enabled, has_fields, whitelabel }) {
      const intake_config = whitelabel?.theme?.intake_config || DEFAULT_INTAKE_CONFIG;

      state.steps = STEPS
        .filter(step => {
          switch (step.name) {
            case 'sign-agreement':
              return !!is_contract_enabled;
            case 'dynamic-data':
              return !!has_fields && intake_config.dynamic_data === 'show';
            case 'documents':
              return intake_config.documents !== 'skip';
          }
          return true;
        });
    },
    setStepNo(state, no) {
      state.step = no;
    }
  }
}
