
<div ref="colorpicker" class="control color-picker">
  <div class="is-flex">
    <input v-model="colorValue" type="text" class="input is-small" @focus="showPicker()" @input="updateFromInput" />
    <div :style="{ background: colorValue, width: '5em' }" class="is-3" @click="togglePicker()"></div>
  </div>
  <span class="input-group-addon color-picker-container">
    <span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
    <ChromePicker v-if="displayPicker" :value="colors" @input="updateFromPicker" />
  </span>
</div>
