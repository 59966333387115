<template>
  <img v-if="src" class="inline-icon" v-svg-inline :src="src" :class="{ 'hidden-box': !src }" />
</template>
<script>
const map = {
  'far,lock': import('../assets/icons/fa/far/lock.svg?url'),
  'far,search': import('@/assets/icons/fa/far/search.svg?url'),
  'far,frown': import('@/assets/icons/fa/far/frown.svg?url'),
  'far,chevron-up': import('@/assets/icons/fa/far/chevron-up.svg?url'),
  'far,chevron-down': import('@/assets/icons/fa/far/chevron-down.svg?url'),
  'fas,minus': import('@/assets/icons/fa/fas/minus.svg?url'),
  'fas,plus': import('@/assets/icons/fa/fas/plus.svg?url'),
  'fas,angle-left': import('@/assets/icons/fa/fas/angle-left.svg?url'),
  'fas,angle-right': import('@/assets/icons/fa/fas/angle-right.svg?url'),
  'fas,arrow-up': import('@/assets/icons/fa/fas/arrow-up.svg?url'),
  'fas,eye': import('@/assets/icons/fa/fas/eye.svg?url'),
  'fas,calendar-day': import('@/assets/icons/fa/fas/calendar-day.svg?url'),
  'fas,check': import('@/assets/icons/fa/fas/check.svg?url'),
  'fas,exclamation-circle': import('@/assets/icons/fa/fas/exclamation-circle.svg?url')
};
export default {
  props: {
    icon: {
      type: Array
    }
  },
  data() {
    return {
      src: null
    }
  },
  async created() {
    const iconName = this.icon.join(',');
    if (!map[iconName]) {
      console.error('missing icon', iconName);
    }
    const url = await map[iconName];
    this.src = url ? url.default : null;
  }
};
</script>
