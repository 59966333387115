<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules">
    <b-field :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0], 'is-success': verified }" :custom-class="verified ? 'is-verified' : ''">
      <b-input v-model="innerValue" v-bind="$attrs"></b-input>
    </b-field>
    <p class="help is-danger">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}

.field {
  margin-bottom: 0;
}
</style>
