<template>
  <div class="field upload-multiple-control" :class="{ 'is-horizontal': horizontal }">
    <div class="is-normal" :class="{ 'field-label': horizontal }">
      <label class="label">
        <div v-if="verified" class="is-verified"></div>
        {{ label }}
      </label>
    </div>
    <div :class="{ 'field-body': horizontal }">
      <div class="field" v-if="!disabled">
        <div v-if="internalValue && internalValue.length > 0" class="control files-list">
          <ul v-if="internalValue.length > 0">
            <li v-for="(upload, idx) in internalValue" :key="idx">
              <a @click.prevent="goToS3(upload.url, upload.legacy_name || getFilename(upload.url))">{{ upload.legacy_name || getFilename(upload.url) }}</a>
              <a v-if="!disabled" class="deselect-upload is-pulled-right" @click.prevent.stop="removeFile(idx)"><img src="@/assets/icons/Remove.svg"/></a>
            </li>
          </ul>
        </div>
        <div class="control image-input" @click="chooseImage">
          <div class="placeholder has-file-selected">
            <div style="width: 100%;">
              <div style="text-align: center;">{{ $t('form.addFile') }}</div>
              <div><b-progress v-if="isUploading" type="is-info" :internalValue="percentCompleted"></b-progress></div>
            </div>
          </div>
          <input ref="fileInput" class="file-input" type="file" :accept="accept" @change="fileChange($event)" />
        </div>
        <p v-if="message" class="help">{{ message }}</p>
      </div>
      <div v-else class="field">
        <div class="control image-input disabled">
          <div class="placeholder has-file-selected">
            <div style="width: 100%;">
              <div style="text-align: center;">
                <ul v-if="internalValue && internalValue.length > 0">
                  <li v-for="(upload, idx) in internalValue" :key="idx">
                    <a @click.prevent="goToS3(upload.url, upload.legacy_name || getFilename(upload.url))">{{ upload.legacy_name || getFilename(upload.url) }}</a>
                    <a v-if="!verified" class="deselect-upload" @click.prevent.stop="removeFile(idx)"><img src="@/assets/icons/Remove.svg"/></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p v-if="message" class="help">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from 'slugify';
import axios from 'axios';
import Utils from '@/utils';
import UploadService from '@/services/UploadService';

export default {
  props: {

    horizontal: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: () => 'File'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    },
    getSignedUrl: {
      type: Function,
      default: () => {}
    },
    value: {
      type: Array,
      default: () => []
    },
    accept: {
      type: String,
      default: '*/*'
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isUploading: false,
      internalValue: this.value ? [].concat(this.value) : [],
      percentCompleted: 0,
      selectedFile: null
    };
  },

  watch: {
    value() {
      this.internalValue = this.value ? [].concat(this.value) : [];
    }
  },

  computed: {},

  async mounted() {},

  methods: {
    getFilename(value) {
      if (!value) return '';
      return Utils.getFilename(value);
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    async fileChange(event) {
      if (event.target.files && event.target.files.length === 1) {
        this.selectedFile = event.target.files[0];
        this.isUploading = true;
        this.percentCompleted = 0;

        try {
          const signature = await this.getSignedUrl(this.selectedFile.name);
          const formdata = new FormData();
          formdata.append('key', signature.fields.key);
          //formdata.append('acl', 'private');

          // put JSON object on formdata (exclude the file fields)
          Object.keys(signature.fields).forEach(key => {
            if (key !== 'key') {
              const value = signature.fields[key];
              formdata.append(key, value);
            }
          });

          const fileName = slugify(this.selectedFile.name, { remove: /[\s$*_+~()'"!:@?]/g });
          formdata.append('file', this.selectedFile, fileName);

          const response = await axios.post(signature.url, formdata, {
            onUploadProgress: progressEvent => {
              this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
          });
          if (response.status === 201) {
            let s3Response = new window.DOMParser().parseFromString(response.data, 'text/xml');
            let url = s3Response.firstChild.children[0].innerHTML;
          } else {
            let s3Error = new window.DOMParser().parseFromString(response.data, 'text/xml');
            let errMsg = s3Error.firstChild.children[0].innerHTML;
            console.error(errMsg);
            this.$buefy.toast.open({
              message: errMsg,
              type: 'is-error'
            });
          }
          this.key = signature.fields.key.replace('${filename}', fileName); // eslint-disable-line no-template-curly-in-string

          // Done uploading
          let tempUrl = `${signature.url}/${this.key}`;

          const arr = this.internalValue || [];
          arr.push({ url: tempUrl, created_at: new Date() });
          this.internalValue = arr;
          await this.emitArr();
          this.selectedFile = null;
          this.isUploading = false;
        } catch (error) {
          console.error(error);
          this.$buefy.toast.open({
            message: error.message,
            type: 'is-error'
          });
          this.isUploading = false;
        }
      }
      return false;
    },
    async removeFile(idx) {
      this.internalValue.splice(idx, 1);
      await this.emitArr();
    },
    async emitArr() {
      this.$emit('update:value', [].concat(this.internalValue));
    },
    async goToS3(url, filename) {
      const presigned = await UploadService.getSignedReadUrl(url, filename);
      window.open(presigned.data.url, '_blank');
    }
  }
};
</script>
