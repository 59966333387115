import CRUDService from './CRUDService';
import http from '@/utils/http';

class ContactService extends CRUDService {
  constructor(path) {
    super(path);
  }

  invite(id) {
    return http({
      url: `api/${this.path}/${id}/invite`,
      method: 'post'
    });
  }

  createMasterAgreement(id, url, file_name) {
    const data = {
      url,
      file_name
    };

    return http({
      url: `api/${this.path}/${id}/master_agreement`,
      method: 'post',
      data
    });
  }

  exportContactsData(query) {
    return http({
      url: `api/${this.path}/export`,
      params: query,
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      timeout: 0
    });
  }
}

export default new ContactService('contacts');
