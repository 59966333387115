<template>
  <div class="field">
    <!--Name: {{name}}-->
    <!--Options: {{options}}-->
    <!--Title: {{options && options.title ? options.title : name}}-->
    <!--Value: {{value}}-->
    <ValidatedInput
      v-if="field.type === 'TEXT'"
      v-model="internal"
      :horizontal="horizontal"
      :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
      :rules="getRules(field)"
      :label="localisedField.label + (field.is_required ? ' *' : '')"
      :placeholder="localisedField.label"
      :vid="field.name"
      :verified="!!verified[field.name]"
      :message="localisedField.description"
      type="text"
      :disabled="disabled"
    ></ValidatedInput>
    <ValidatedInput
      v-if="field.type === 'MULTILINE_TEXT'"
      v-model="internal"
      :horizontal="horizontal"
      :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
      :rules="getRules(field)"
      :label="localisedField.label + (field.is_required ? ' *' : '')"
      :placeholder="localisedField.label"
      :vid="field.name"
      :verified="!!verified[field.name]"
      :message="localisedField.description"
      type="textarea"
      :disabled="disabled"
    ></ValidatedInput>
    <ValidatedInput
      v-if="field.type === 'NUMBER'"
      v-model="internal"
      :horizontal="horizontal"
      :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
      :rules="getRules(field)"
      :label="localisedField.label + (field.is_required ? ' *' : '')"
      :placeholder="localisedField.label"
      :vid="field.name"
      :verified="!!verified[field.name]"
      :message="localisedField.description"
      type="number"
      :disabled="disabled"
    ></ValidatedInput>
    <ValidatedCurrencyInput
      v-if="field.type === 'CURRENCY'"
      v-model="internal"
      :horizontal="horizontal"
      :allowed-currencies="field.allowed_currencies"
      :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
      :rules="getRules(field)"
      :label="localisedField.label + (field.is_required ? ' *' : '')"
      :placeholder="localisedField.label"
      :vid="field.name"
      :verified="!!verified[field.name]"
      :message="localisedField.description"
      :disabled="disabled"
    />
    <ValidatedSelect
      v-if="field.type === 'LIST'"
      v-model="internal"
      :horizontal="horizontal"
      :options="field.options"
      :reduce="option => option[locale] || option['label']"
      :option-label="locale"
      fallback-option-label="label"
      :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
      :rules="getRules(field)"
      :label="localisedField.label + (field.is_required ? ' *' : '')"
      :placeholder="localisedField.label"
      :get-option-label="option => (option[locale] ? option[locale] : option.label)"
      :vid="field.name"
      :verified="!!verified[field.name]"
      :message="localisedField.description"
      :disabled="disabled"
    ></ValidatedSelect>
    <div v-if="field.type === 'CHECKBOX'" style="margin-bottom: 10px;">
      <label class="label" :class="{ 'is-verified': !!verified[field.name] }">{{ localisedField.label + (field.is_required ? ' *' : '') }}</label>
      <div v-for="option in field.options" :key="option.value" class="block" style="margin-top: 10px;">
        <b-checkbox v-model="internal" :native-value="option.value" :disabled="disabled">
          {{ option.label }}
        </b-checkbox>
      </div>
      <p v-if="localisedField.description" class="help">{{ localisedField.description }}</p>
    </div>
    <div v-if="field.type === 'RADIO'" style="margin-bottom: 10px;">
      <label class="label" :class="{ 'is-verified': !!verified[field.name] }">{{ localisedField.label + (field.is_required ? ' *' : '') }}</label>
      <div v-for="option in field.options" :key="option.value" class="block" style="margin-top: 10px;">
        <b-radio v-model="internal" :native-value="option.value" :disabled="disabled">
          {{ option.label }}
        </b-radio>
      </div>
      <p v-if="localisedField.description" class="help">{{ localisedField.description }}</p>
    </div>
    <ValidatedDate
      v-if="field.type === 'DATE'"
      v-model="internal"
      :horizontal="horizontal"
      :options="field.options"
      :reduce="option => option[locale] || option['label']"
      :option-label="locale"
      fallback-option-label="label"
      :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
      :rules="getRules(field)"
      :label="localisedField.label"
      :placeholder="localisedField.label"
      :get-option-label="option => (option[locale] ? option[locale] : option.label)"
      :vid="field.name"
      :verified="!!verified[field.name]"
      :message="localisedField.description"
      :disabled="disabled"
    />
    <div v-if="field.type === 'CONSENT'" style="margin-bottom: 10px;">
      <ConsentField
        v-model="internal"
        :horizontal="horizontal"
        :label="localisedField.label"
        :description="localisedField.description"
        :disabled="disabled"
        :verified="!!verified[field.name]"
        :field="field"
        :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
      ></ConsentField>
    </div>
    <DynamicUploadControl
      v-if="field.type === 'UPLOAD'"
      v-model="internal"
      :accept="field.accept"
      :horizontal="horizontal"
      :case-id="caseId"
      :label="localisedField.label"
      :disabled="disabled"
      :verified="!!verified[field.name]"
      :message="localisedField.description"
    />
    <DynamicMultipleUploadControl
      v-if="field.type === 'UPLOAD_MULTIPLE'"
      v-model="internal"
      :accept="field.accept"
      :horizontal="horizontal"
      :case-id="caseId"
      :label="localisedField.label"
      :disabled="disabled"
      :verified="!!verified[field.name]"
      :message="localisedField.description"
    />
    <DynamicUploadSignedControl
      v-if="field.type === 'UPLOAD_SIGNED'"
      v-model="internal"
      :accept="field.accept"
      :horizontal="horizontal"
      :global-document-to-be-signed="localisedField.document_to_be_signed"
      :local-document-to-be-signed="value ? value.document_to_be_signed : null"
      :case-id="caseId"
      :label="localisedField.label"
      :disabled="disabled"
      :verified="!!verified[field.name]"
      :field="field"
      :message="localisedField.description"
    />
    <DynamicTable v-if="field.type === 'COLLECTION'" v-model="internal" :collection="field" :case-id="caseId" :disabled="disabled" :verified="verified" :message="localisedField.description" :horizontal="horizontal" />
  </div>
</template>

<script>
import ValidatedInput from '@/components/form/ValidatedInput.vue';
import ValidatedCurrencyInput from '@/components/form/ValidatedCurrencyInput.vue';
import ValidatedSelect from '@/components/form/ValidatedSelect.vue';
import ValidatedDate from '@/components/form/ValidatedDate.vue';
import DynamicTable from './DynamicTable.vue';
import DynamicUploadControl from './DynamicUploadControl.vue';
import DynamicMultipleUploadControl from './DynamicMultipleUploadControl.vue';
import DynamicUploadSignedControl from './DynamicUploadSignedControl.vue';
import ConsentField from './ConsentField.vue';

export default {
  name: 'Field',

  components: { ValidatedInput, ValidatedCurrencyInput, ValidatedSelect, DynamicTable, DynamicUploadControl, DynamicMultipleUploadControl, DynamicUploadSignedControl, ValidatedDate, ConsentField },

  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    field: {
      type: Object,
      default: () => {}
    },
    locale: {
      type: String,
      default: () => 'en'
    },
    caseId: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      data: [],
      error: null
    };
  },

  computed: {
    isBackOffice() {
      return this.$route.path.startsWith('/admin/');
    },
    localisedField() {
      if (!this.locale || this.locale === 'en') {
        return this.field;
      } else {
        let translation = this.field.translations.find(translation => {
          return translation.lang === this.locale;
        });

        return translation || { label: this.field.label };
      }
    },
    internal: {
      get() {
        if (this.field && this.field.type === 'datetime') {
          return this.value ? this.parseDate(this.value) : new Date();
        } else if (this.field && (this.field.type === 'json' || this.field.type === 'code')) {
          if (!(typeof this.value === 'string' || this.value instanceof String)) {
            return JSON.stringify(this.value);
          }
          return this.value;
        } else if (this.field && this.field.type === 'CHECKBOX') {
          return this.value ? this.value : [];
        } else if (this.field && this.field.type === 'UPLOAD_SIGNED') {
          return this.value ? this.value.value : null;
        } else if (this.field && this.field.type === 'CONSENT') {
          return this.value ? this.value : { consented: false };
        }
        return this.value;
      },
      set(newValue) {
        // When the internal value changes, we $emit an event. Because this event is
        // named 'input', v-model will automatically update the parent value
        if (this.field && this.field.type === 'json') {
          if (!(typeof this.value === 'string' || this.value instanceof String)) {
            this.$emit('input', JSON.stringify(this.value));
          }
        } else if (this.field && this.field.type === 'UPLOAD_SIGNED') {
          let newObjectValue = Object.assign({}, this.value, { value: newValue });
          this.$emit('input', newObjectValue);
        } else {
          this.$emit('input', newValue);
        }
      }
    }
  },

  created() {},

  methods: {
    parseDate(dateString) {
      return new Date(Date.parse(dateString));
    },
    fileChange(name, event) {
      if (event.target.files && event.target.files.length === 1) {
        this.internal = event.target.files[0];
      }
    },
    clear() {
      this.$emit('input', null);
    },
    getRules(field) {
      let rules = field.rules ? field.rules.split('|') : [];

      if (field.type === 'NUMBER') {
        rules.push('number');
      }

      if (field.is_required) {
        rules.push('required');
      }
      if (field.min_value) {
        rules.push('min_value:' + field.min_value);
      }
      if (field.max_value) {
        rules.push('max_value:' + field.max_value);
      }
      if (field.min_date) {
        rules.push('min_date:' + field.min_date);
      }
      if (field.max_date) {
        rules.push('max_date:' + field.max_date);
      }

      return rules.join('|');
    }
  }
};
</script>
