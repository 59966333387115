<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules" class="validated-select">
    <b-field :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0] }" :custom-class="verified ? 'is-verified' : ''">
      <b-select v-model="innerValue" :field="$attrs.name || $attrs.label" :disabled="disabled">
        <option v-for="option in options" :key="reduce(option)" :value="reduce(option)">
          {{ getOptionLabel(option) }}
        </option>
      </b-select>
    </b-field>
    <p class="help is-danger">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    reduce: {
      type: Function,
      default: function(val) {
        return val;
      }
    },
    getOptionLabel: {
      type: Function,
      default: function(val) {
        return val[this.optionLabel];
      }
    },
    optionLabel: {
      type: String,
      default: 'name'
    },
    fallbackOptionLabel: {
      type: String,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}

.field {
  margin-bottom: 0;
}

::v-deep .select,
::v-deep select {
  display: block;
  width: 100%;
}
</style>
