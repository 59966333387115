const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

const getFilename = url => {
  if (url) {
    return url.replace(/\?.*$/, '').replace(/.*\//, '');
    // return url.split('/').pop();
  }
  return null;
};

const getExtension = filename => {
  let parts = filename.split('.');
  return parts[parts.length - 1];
};

const getFileType = filename => {
  if (!filename) return 'empty';
  let ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
      return 'image';
    case 'gif':
      return 'image';
    case 'bmp':
      return 'image';
    case 'png':
      return 'image';
    case 'pdf':
      return 'pdf';
  }

  return 'unknown';
};

const getFileTypeFormUrl = url => {
  let fileName = getFilename(url);
  return getFileType(fileName);
};

function poll(fn, timeout, interval) {
  const endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = async function(resolve, reject) {
    // If the condition is met, we're done!

    const result = await fn();

    if (result) {
      resolve(result);
    }
    // If the condition isn't met but the timeout hasn't elapsed, go again
    else if (!result && Number(new Date()) < endTime) {
      setTimeout(checkCondition, interval, resolve, reject);
    }
    // Didn't match and too much time, reject!
    else if (Number(new Date()) > endTime) {
      reject(new Error('timed out for ' + fn + ': ' + arguments));
    }
  };

  return new Promise(checkCondition);
}

export default { arrayToObject, getFilename, getExtension, getFileType, getFileTypeFormUrl, poll };
