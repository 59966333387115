
<div class="modal-card" style="width: 600px">
  <header class="modal-card-head">
    <p v-if="!editMode" class="modal-card-title">Add Row</p>
    <p v-if="editMode" class="modal-card-title">Edit Row</p>
  </header>
  <section class="modal-card-body">
    <DynamicForm :disabled="disabled" :fields="fields" :entity="initialValue" :case-id="caseId" :on-submit="forwardSumbit" :on-cancel="onCancel"></DynamicForm>
  </section>
</div>
