
<div>
  <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="() => {}">
    <div v-for="field in fieldsSorted" :key="field.id">
      <div v-if="isKindVisible(kind, field.type)" class="columns">
        <div v-if="field.type !== 'COLLECTION' && allowVerification" class="column is-1">
          <label class="label">Verified</label>
          <a @click="toggleStatus(entity, field.name)">
            <img v-if="entity && entity.verified && entity.verified[field.name]" src="@/assets/icons/figma/Checkbox.svg" style="height: 24px" />
            <img v-else src="@/assets/icons/figma/Checkbox-empty.svg" style="height: 24px" />
          </a>
        </div>
        <div class="column">
          <Field :horizontal="horizontal" v-model="entity[field.name]" :field="field" :locale="language" :case-id="caseId" :disabled="fieldDisabled(entity, field)" :verified="verified" />
        </div>
      </div>
    </div>
    <slot name="buttons">
      <div style="margin-top:1em; text-align:right;">
        <slot name="controls" :cancel="cancelForm" :submit="submitForm">
          <div class="buttons is-pulled-right">
            <button type="button" class="button is-outlined is-primary" @click="cancelForm">{{ $t('form.cancel') }}</button>
            <button type="button" class="button is-primary" @click="submitForm" v-if="!disabled">{{ $t('form.save') }}</button>
          </div>
        </slot>
      </div>
    </slot>
  </ValidationObserver>
</div>
