import CRUDService from './CRUDService';
import http from '../utils/http';

class CaseRelationService extends CRUDService {
  constructor(path) {
    super(path);
  }

  resetRelations(caseId) {
    return http({
      url: `api/cases/${caseId}/relations/reset`,
      method: 'delete'
    });
  }
}

export default new CaseRelationService('cases/relations');
