import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

function formatDate(value, format) {
  if (value) {
    return dayjs(String(value)).format(format || 'DD/MM/YYYY');
  }

  return '';
}

function fromNow(value) {
  if (value) {
    return dayjs(String(value)).fromNow();
  }

  return '';
}

function pretty(value) {
  try {
    if (typeof value === 'string' ) {
      value = JSON.parse(value);
    }
    const beautified = JSON.stringify(value, null, 2);
    return beautified;
  } catch (e) {
    return value;
  }
}

function euro(value) {
  let internal = value;
  if (typeof internal !== 'number') {
    internal = Number(internal);
  }
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });

  return formatter.format(value);
}

function twoDigits(value) {
  if (value < 0) {
    return '00';
  }
  if (value.toString().length <= 1) {
    return `0${value}`;
  }
  return value;
}

// https://github.com/imcvampire/vue-truncate-filter
function truncate(text, length, clamp) {
  text = text || '';
  clamp = clamp || '...';
  length = length || 30;

  if (text.length <= length) return text;

  var tcText = text.slice(0, length - clamp.length);
  var last = tcText.length - 1;

  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1;

  // Fix for case when text dont have any `space`
  last = last || length - clamp.length;

  tcText = tcText.slice(0, last);

  return tcText + clamp;
}

export function contactName(contact) {
  if (!contact) return '';
  if (contact.name) return contact.name;
  if (contact.firstname || contact.lastname) {
    return (contact.firstname || '') + ' ' + (contact.lastname || '');
  }
  return '';
}

export default { formatDate, fromNow, pretty, euro, twoDigits, truncate, contactName };
