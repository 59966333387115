
<div class="field">
  <!--Name: {{name}}-->
  <!--Options: {{options}}-->
  <!--Title: {{options && options.title ? options.title : name}}-->
  <!--Value: {{value}}-->
  <ValidatedInput
    v-if="field.type === 'TEXT'"
    v-model="internal"
    :horizontal="horizontal"
    :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
    :rules="getRules(field)"
    :label="localisedField.label + (field.is_required ? ' *' : '')"
    :placeholder="localisedField.label"
    :vid="field.name"
    :verified="!!verified[field.name]"
    :message="localisedField.description"
    type="text"
    :disabled="disabled"
  ></ValidatedInput>
  <ValidatedInput
    v-if="field.type === 'MULTILINE_TEXT'"
    v-model="internal"
    :horizontal="horizontal"
    :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
    :rules="getRules(field)"
    :label="localisedField.label + (field.is_required ? ' *' : '')"
    :placeholder="localisedField.label"
    :vid="field.name"
    :verified="!!verified[field.name]"
    :message="localisedField.description"
    type="textarea"
    :disabled="disabled"
  ></ValidatedInput>
  <ValidatedInput
    v-if="field.type === 'NUMBER'"
    v-model="internal"
    :horizontal="horizontal"
    :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
    :rules="getRules(field)"
    :label="localisedField.label + (field.is_required ? ' *' : '')"
    :placeholder="localisedField.label"
    :vid="field.name"
    :verified="!!verified[field.name]"
    :message="localisedField.description"
    type="number"
    :disabled="disabled"
  ></ValidatedInput>
  <ValidatedCurrencyInput
    v-if="field.type === 'CURRENCY'"
    v-model="internal"
    :horizontal="horizontal"
    :allowed-currencies="field.allowed_currencies"
    :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
    :rules="getRules(field)"
    :label="localisedField.label + (field.is_required ? ' *' : '')"
    :placeholder="localisedField.label"
    :vid="field.name"
    :verified="!!verified[field.name]"
    :message="localisedField.description"
    :disabled="disabled"
  />
  <ValidatedSelect
    v-if="field.type === 'LIST'"
    v-model="internal"
    :horizontal="horizontal"
    :options="field.options"
    :reduce="option => option[locale] || option['label']"
    :option-label="locale"
    fallback-option-label="label"
    :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
    :rules="getRules(field)"
    :label="localisedField.label + (field.is_required ? ' *' : '')"
    :placeholder="localisedField.label"
    :get-option-label="option => (option[locale] ? option[locale] : option.label)"
    :vid="field.name"
    :verified="!!verified[field.name]"
    :message="localisedField.description"
    :disabled="disabled"
  ></ValidatedSelect>
  <div v-if="field.type === 'CHECKBOX'" style="margin-bottom: 10px;">
    <label class="label" :class="{ 'is-verified': !!verified[field.name] }">{{ localisedField.label + (field.is_required ? ' *' : '') }}</label>
    <div v-for="option in field.options" :key="option.value" class="block" style="margin-top: 10px;">
      <b-checkbox v-model="internal" :native-value="option.value" :disabled="disabled">
        {{ option.label }}
      </b-checkbox>
    </div>
    <p v-if="localisedField.description" class="help">{{ localisedField.description }}</p>
  </div>
  <div v-if="field.type === 'RADIO'" style="margin-bottom: 10px;">
    <label class="label" :class="{ 'is-verified': !!verified[field.name] }">{{ localisedField.label + (field.is_required ? ' *' : '') }}</label>
    <div v-for="option in field.options" :key="option.value" class="block" style="margin-top: 10px;">
      <b-radio v-model="internal" :native-value="option.value" :disabled="disabled">
        {{ option.label }}
      </b-radio>
    </div>
    <p v-if="localisedField.description" class="help">{{ localisedField.description }}</p>
  </div>
  <ValidatedDate
    v-if="field.type === 'DATE'"
    v-model="internal"
    :horizontal="horizontal"
    :options="field.options"
    :reduce="option => option[locale] || option['label']"
    :option-label="locale"
    fallback-option-label="label"
    :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
    :rules="getRules(field)"
    :label="localisedField.label"
    :placeholder="localisedField.label"
    :get-option-label="option => (option[locale] ? option[locale] : option.label)"
    :vid="field.name"
    :verified="!!verified[field.name]"
    :message="localisedField.description"
    :disabled="disabled"
  />
  <div v-if="field.type === 'CONSENT'" style="margin-bottom: 10px;">
    <ConsentField
      v-model="internal"
      :horizontal="horizontal"
      :label="localisedField.label"
      :description="localisedField.description"
      :disabled="disabled"
      :verified="!!verified[field.name]"
      :field="field"
      :readonly="!isBackOffice && field.visibility === 'READ_ONLY'"
    ></ConsentField>
  </div>
  <DynamicUploadControl
    v-if="field.type === 'UPLOAD'"
    v-model="internal"
    :accept="field.accept"
    :horizontal="horizontal"
    :case-id="caseId"
    :label="localisedField.label"
    :disabled="disabled"
    :verified="!!verified[field.name]"
    :message="localisedField.description"
  />
  <DynamicMultipleUploadControl
    v-if="field.type === 'UPLOAD_MULTIPLE'"
    v-model="internal"
    :accept="field.accept"
    :horizontal="horizontal"
    :case-id="caseId"
    :label="localisedField.label"
    :disabled="disabled"
    :verified="!!verified[field.name]"
    :message="localisedField.description"
  />
  <DynamicUploadSignedControl
    v-if="field.type === 'UPLOAD_SIGNED'"
    v-model="internal"
    :accept="field.accept"
    :horizontal="horizontal"
    :global-document-to-be-signed="localisedField.document_to_be_signed"
    :local-document-to-be-signed="value ? value.document_to_be_signed : null"
    :case-id="caseId"
    :label="localisedField.label"
    :disabled="disabled"
    :verified="!!verified[field.name]"
    :field="field"
    :message="localisedField.description"
  />
  <DynamicTable v-if="field.type === 'COLLECTION'" v-model="internal" :collection="field" :case-id="caseId" :disabled="disabled" :verified="verified" :message="localisedField.description" :horizontal="horizontal" />
</div>
