import { SnackbarProgrammatic as Snackbar } from 'buefy';
const initialState = () => ({
  type: null,
  message: null
});

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    success({ commit }, message) {
      commit('success', message);
    },
    error({ commit }, message) {
      commit('error', message);
    },
    clear({ commit }, message) {
      commit('success', message);
    },
    snackbar(context, { message, type = 'is-success', duration = 2000, indefinite = false, actionText = null }) {
      Snackbar.open({
        message,
        type,
        position: 'is-top',
        actionText,
        duration,
        indefinite
      });
    }
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    success(state, message) {
      state.type = 'alert-success';
      state.message = message;
    },
    error(state, message) {
      state.type = 'alert-danger';
      state.message = message;
    },
    clear(state) {
      state.type = null;
      state.message = null;
    }
  }
};
