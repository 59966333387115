import CRUDService from './CRUDService';
import http from '../utils/http';

class CaseTypeService extends CRUDService {
  constructor(path) {
    super(path);
  }

  exportCasesData({ casetype_id }) {
    return http({
      url: `api/casetypes/${casetype_id}/export/cases_data`,
      method: 'POST',
      timeout: 0,
      data: {}
    });
  }

  importCasesData({ casetype_id, file }) {
    return http({
      url: `api/casetypes/${casetype_id}/import/cases_data`,
      method: 'POST',
      headers: {
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      timeout: 0,
      data: file
    });
  }

  exportCollectionsData({ casetype_id }) {
    return http({
      url: `api/casetypes/${casetype_id}/export/collections_data`,
      method: 'POST',
      timeout: 0,
      data: {}
    });
  }

  importCollectionsData({ casetype_id, file }) {
    return http({
      url: `api/casetypes/${casetype_id}/import/collections_data`,
      method: 'POST',
      headers: {
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      timeout: 0,
      data: file
    });
  }

  importClaimsContacts({ casetype_id, file }) {
    return http({
      url: `api/jobs/import-case-claims-contacts/${casetype_id}`,
      method: 'POST',
      headers: {
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      timeout: 0,
      data: file
    });
  }

  importCaseClaimsDocuments({ casetype_id, url }) {
    return http({
      url: `api/jobs/import-case-claims-documents/${casetype_id}`,
      method: 'POST',
      timeout: 0,
      data: {
        url
      }
    });
  }

  exportDocuments({ casetype_id, data }) {
    return http({
      url: `api/casetypes/${casetype_id}/export/documents`,
      method: 'POST',
      timeout: 0,
      data
    });
  }

  getNews({ casetype_id, news_id }) {
    if (news_id) {
      return http({
        url: `api/casetypes/${casetype_id}/news/${news_id}`,
        method: 'GET'
      });
    }

    return http({
      url: `api/casetypes/${casetype_id}/news`,
      method: 'GET',
      params: {
        _orderBy: '-publish_at'
      }
    });
  }

  exportAllClaims({ casetype_id, selectedCollection, selectedSortColumn, selectedSortDir }) {
    return http({
      url: `api/casetypes/${casetype_id}/export/all_claims`,
      method: 'POST',
      timeout: 0,
      data: {
        selectedCollection,
        selectedSortColumn,
        selectedSortDir
      }
    });
  }

  uploadStatus({ casetype_id, files }) {
    return http({
      url: `api/casetypes/${casetype_id}/upload_status`,
      method: 'POST',
      timeout: 0,
      data: {
        files
      }
    });
  }

  validateTemplate({ casetype_id, file }) {
    return http({
      url: `api/${this.path}/${casetype_id}/validate_template`,
      method: 'POST',
      headers: {
        'Content-type': 'application/word'
      },
      timeout: 0,
      data: file
    });
  }
}

export default new CaseTypeService('casetypes');
