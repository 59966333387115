<template>
  <div class="field upload-control" :class="{ 'is-horizontal': horizontal }">
    <div :class="{ 'field-label': horizontal }">
      <label v-if="!hideLabel" class="label mb-2">
        <div v-if="verified" class="is-verified"></div>
        {{ label }}
      </label>
    </div>
    <div v-if="!disabled" :class="{ 'field-body': horizontal }">
      <div class="field">
        <div class="control image-input card-document-upload" @click="chooseImage">
          <div v-if="isUploading" class="placeholder has-file-selected">
            <div style="width: 100%;">
              <div style="text-align: center;">
                <a @click.prevent.stop="goToS3(internalValue, displayFileName)">{{ displayFileName }}</a>
              </div>
              <div><b-progress type="is-info" :value="percentCompleted" /></div>
            </div>
          </div>
          <div v-else-if="!internalValue" class="placeholder">
          <span class="back-icon">
            <img v-svg-inline class="inline-icon arrow-up" :src="require('@/assets/icons/fa/far/arrow-up.svg')" />
          </span>
          <a class="deselect-upload" @click.prevent.stop="clearFile()"><img src="@/assets/icons/trash.svg"/></a>

            {{ $t('common.upload') }} {{ label }}
          </div>
          <div v-else class="placeholder has-file-selected">
            <div style="width: 100%;">
              <div style="text-align: center;">
                <a @click.prevent.stop="goToS3(internalValue, displayFileName)">{{ displayFileName }}</a>
              </div>
            </div>
            <a class="deselect-upload" @click.prevent.stop="clearFile()"><img src="@/assets/icons/trash.svg"/></a>
          </div>

          <input ref="fileInput" class="file-input" type="file" :accept="accept" @change="fileChange($event)" />
        </div>
        <p v-if="message" class="help">{{ message }}</p>
      </div>
    </div>
    <div v-else :class="{ 'field-body': horizontal }">
      <div class="control image-input disabled">
        <div class="placeholder has-file-selected">
          <div style="width: 100%;">
            <div style="text-align: center;">
              <a @click.prevent.stop="goToS3(internalValue, displayFileName)">{{ displayFileName }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-if="errors" class="help has-text-danger">{{ errors[0] }}</p>
  </div>
</template>

<script>
import slugify from 'slugify';
import axios from 'axios';
import Utils from '@/utils';
import UploadService from '@/services/UploadService';
import FrontendService from '@/services/FrontendService';

export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: () => 'File'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    },
    validateFile: {
      type: Function,
      default: null
    },
    getSignedUrl: {
      type: Function,
      default: () => {}
    },
    value: {
      type: String,
      default: null
    },
    directory: {
      type: String,
      default: null
    },
    filename: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: null
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    message: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isUploading: false,
      internalFilename: this.filename,
      internalValue: this.value,
      percentCompleted: 0,
      selectedFile: null
    };
  },

  watch: {
    value() {
      this.internalValue = this.value;
    },
    filename() {
      this.internalFilename = this.filename;
    }
  },

  computed: {
    displayFileName() {
      if (this.internalFilename) {
        return this.internalFilename;
      } else {
        return Utils.getFilename(this.internalValue);
      }
    }
  },

  async mounted() {},

  methods: {
    async getPresignedDirectory(fileName) {
      let response = await UploadService.getPresignedDirectory(this.directory, fileName);
      return response.data;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    clearFile() {
      this.$refs.fileInput.value = null;
      this.internalValue = '';
      this.internalFilename = '';
      this.$emit('update:filename', this.internalFilename);
      this.$emit('update:value', this.internalValue);
    },
    async fileChange(event) {
      if (event.target.files && event.target.files.length === 1) {
        this.selectedFile = event.target.files[0];
        this.isUploading = true;
        this.internalFilename = slugify(this.selectedFile.name, { remove: /[\s$*_+~()'"!:@?]/g });
        this.$emit('update:filename', this.internalFilename);
        this.percentCompleted = 0;

        if (this.validateFile) {
          const isValid = await this.validateFile(this.selectedFile);
          if (!isValid) {
            this.clearFile();
            return false;
          }
        }

        try {
          const signature = this.directory ? await this.getPresignedDirectory(this.selectedFile.name) : await this.getSignedUrl(this.selectedFile.name);
          const formdata = new FormData();
          formdata.append('key', signature.fields.key);
          //formdata.append('acl', 'private');

          // put JSON object on formdata (exclude the file fields)
          Object.keys(signature.fields).forEach(key => {
            if (key !== 'key') {
              const value = signature.fields[key];
              formdata.append(key, value);
            }
          });

          const fileName = slugify(this.selectedFile.name, { remove: /[\s$*_+~()'"!:@?]/g });
          formdata.append('file', this.selectedFile, fileName);

          const response = await axios.post(signature.url, formdata, {
            onUploadProgress: progressEvent => {
              this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
          });
          if (response.status === 201) {
            let s3Response = new window.DOMParser().parseFromString(response.data, 'text/xml');
            let url = s3Response.firstChild.children[0].innerHTML;
          } else {
            let s3Error = new window.DOMParser().parseFromString(response.data, 'text/xml');
            let errMsg = s3Error.firstChild.children[0].innerHTML;
            this.$buefy.toast.open({
              message: errMsg,
              type: 'is-error'
            });
          }
          this.key = signature.fields.key.replace('${filename}', fileName); // eslint-disable-line no-template-curly-in-string

          // Done uploading
          let tempUrl = `${signature.url}/${this.key}`;

          this.internalFilename = fileName;
          this.internalValue = tempUrl;
          this.$emit('update:filename', this.internalFilename);
          this.$emit('update:value', this.internalValue);
          this.selectedFile = null;
          this.isUploading = false;
        } catch (error) {
          console.error(error);
          this.$buefy.toast.open({
            message: error.message,
            type: 'is-error'
          });
          this.isUploading = false;
        }
      }
      return false;
    },
    async goToS3(url, filename) {
      const presigned = await FrontendService.getSignedReadUrl(url, filename);
      window.open(presigned.data.url, '_blank');
    }
  }
};
</script>
