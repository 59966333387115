<template>
  <div ref="colorpicker" class="control color-picker">
    <div class="is-flex">
      <input v-model="colorValue" type="text" class="input is-small" @focus="showPicker()" @input="updateFromInput" />
      <div :style="{ background: colorValue, width: '5em' }" class="is-3" @click="togglePicker()"></div>
    </div>
    <span class="input-group-addon color-picker-container">
      <span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
      <ChromePicker v-if="displayPicker" :value="colors" @input="updateFromPicker" />
    </span>
  </div>
</template>
<script>
import ChromePicker from 'vue-color/src/components/Chrome.vue';

export default {
  components: {
    ChromePicker
  },
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      displayPicker: false
    };
  },
  watch: {
    value() {
      this.setColor(this.value);
    },
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit('input', val);
        //document.body.style.background = val;
      }
    }
  },
  mounted() {
    this.setColor(this.value);
  },
  methods: {
    setColor(color) {
      if (!color) return;
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        };
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3]
        };
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a === 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker;
      const target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    }
  }
};
</script>
