import { omit, get } from 'lodash';
import CaseService from '@/services/CaseService';
import CaseTypeService from '@/services/CaseTypeService';
import ContactService from '@/services/ContactService';
import CaseRelationService from '@/services/CaseRelationService';
import CaseRelationDocumentService from '@/services/CaseRelationDocumentService';

const initialState = () => {
  return {
    casetype: {},
    claim: {},
    relations: [],
    documents: []
  };
};

// case is a RESERVED KEYWORD
export default {
  namespaced: true,
  state: initialState(),
  actions: {
    // async createCase({ commit }, casetype_id) {
    //   let response = await CaseService.create({ casetype_id });
    //   let claim = response.data;
    //   commit('setCase', claim);
    // },
    async fetchCaseType({ commit }, casetype_id) {
      let response = await CaseTypeService.getOne(casetype_id);
      let casetype = response.data;
      commit('setCaseType', casetype);
    },
    async fetchCase({ commit }, case_id) {
      let response = await CaseService.getOne(case_id, { _related: ['caseRelations', 'caseRelations.contact'] });
      let claim = response.data;
      commit('setCase', omit(claim, 'caseRelations'));

      let relations = get(claim, ['caseRelations']);
      relations.sort((a, b) => {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      });
      commit('setRelations', relations);

      let documentsResponse = await CaseRelationDocumentService.getAll({ case_id, _related: ['documentType', 'files'] });
      let documents = documentsResponse.data.data;

      commit('setDocuments', documents);
    },
    async setCaseMeta({ commit, state }, intake_meta) {
      let newMeta = Object.assign({}, state.claim.intake_meta, intake_meta);
      const completedSteps = newMeta.completed_steps;
      const case_relations_complete = completedSteps['general-info'] && completedSteps['claimant-info'] && completedSteps['documents'] && completedSteps['review-and-submit'];
      await CaseService.update(state.claim.id, { intake_meta: newMeta, case_relations_complete });
      commit('patchCaseMeta', newMeta);
    },
    async resetCaseContactTree({ state, dispatch }) {
      await CaseRelationService.resetRelations(state.claim.id);
      // Refresh the claim
      await dispatch('fetchCase', state.claim.id);
    },
    async createRelation({ commit, state }, { relation_parent_id, relation_type, contact_type, initial_contact_id }) {
      let contactId = initial_contact_id;
      let contact = null;

      if (!contactId) {
        let contactResponse = await ContactService.create({ type: contact_type });
        contactId = contactResponse.data.id;
        contact = contactResponse.data;
      } else {
        let contactResponse = await ContactService.getOne(contactId);
        contact = contactResponse.data;
      }

      let relationResponse = await CaseRelationService.create({
        case_id: state.claim.id,
        parent_id: relation_parent_id,
        type: relation_type,
        contact_id: contactId
      });

      let relation = Object.assign(relationResponse.data, { contact });
      commit('addRelation', relation);

      let documentsResponse = await CaseRelationDocumentService.getAll({ case_relation_id: relation.id, _related: ['documentType', 'files'] });
      let documents = documentsResponse.data.data;

      commit('addDocuments', documents);

      return relation;
    },
    async changeRelationContact({ commit, state }, { relation_id, contact_id }) {
      let response = await CaseRelationService.update(relation_id, { contact_id });
      let updatedRelation = response.data;
      let relationResponse = await CaseService.getRelation(state.claim.id, updatedRelation.id, { _related: ['contact'] });
      let relation = relationResponse.data;

      commit('updateRelation', { id: relation.id, relation });
    },
    async deleteRelation({ commit }, { relation_id }) {
      await CaseRelationService.delete(relation_id);
      commit('removeRelation', relation_id);
      commit('removeDocuments', { case_relation_id: relation_id });
    },
    async createContact(args, { contact_type }) {
      let contactResponse = await ContactService.create({ type: contact_type });
      return contactResponse.data;
    },
    async updateContact({ commit }, { id, data }) {
      let response = await ContactService.update(id, data);
      let contact = response.data;
      commit('setContact', contact);
    },
    async addRelationDocumentFile({ commit, state }, { case_relation_document_id, url }) {
      let response = await CaseService.createRelationFile(state.claim.id, { case_relation_document_id, url });
      let file = response.data;
      commit('addDocumentFile', { case_relation_document_id, file });
    },
    async removeRelationDocumentFile({ commit, state }, { case_relation_document_id, file_id }) {
      await CaseService.deleteRelationFile(state.claim.id, file_id);
      commit('removeDocumentFile', { case_relation_document_id, file_id });
    },
    async updateRelationDocumentFile({ commit, state }, { file_id, url }) {
      let fileResponse = await CaseService.updateRelationFile(state.claim.id, file_id, { url });
      let file = fileResponse.data;
      commit('updateDocumentFile', { case_relation_document_id: file.case_relation_document_id, file });
    },
    async generateContract({ state }) {
      await CaseService.generateContract(state.claim.id);
    },
    async updateCaseLifecycle(args, { caseId, nextState }) {
      await CaseService.updateLifecycle(caseId, nextState);
    }
  },
  mutations: {
    setCaseType(state, casetype) {
      state.casetype = casetype;
    },
    setCase(state, claim) {
      state.claim = claim;
    },
    patchCaseMeta(state, intake_meta) {
      state.claim.intake_meta = Object.assign(state.claim.intake_meta, intake_meta);
    },
    setRelations(state, relations) {
      state.relations.splice(0, state.relations.length, ...relations);
    },
    setDocuments(state, documents) {
      state.documents.splice(0, state.documents.length, ...documents);
    },
    addDocuments(state, documents) {
      state.documents = state.documents.concat(documents);
    },
    removeDocuments(state, params) {
      const documents = state.documents.filter(document => {
        let same = true;
        for (let k in params) {
          if (document[k] !== params[k]) same = false;
        }
        return !same;
      });
      state.documents.splice(0, state.documents.length, ...documents);
    },
    addRelation(state, relation) {
      state.relations.push(relation);
    },
    updateRelation(state, { id, relation }) {
      let index = state.relations.findIndex(relation => relation.id === id);
      if (index !== -1) state.relations.splice(index, 1, relation);
    },
    removeRelation(state, case_relation_id) {
      // console.log("RemoveRelation", case_relation_id);
      let index = state.relations.findIndex(relation => relation.id === case_relation_id);
      state.relations.splice(index, 1);
    },
    setContact(state, contact) {
      for (let i = 0; i < state.relations.length; i++) {
        let relation = state.relations[i];
        if (relation.contact_id === contact.id) {
          state.relations.splice(i, 1, Object.assign(relation, { contact }));
        }
      }
    },
    addDocumentFile(state, { case_relation_document_id, file }) {
      let index = state.documents.findIndex(document => document.id === case_relation_document_id);
      state.documents[index].files.push(file);
    },
    removeDocumentFile(state, { case_relation_document_id, file_id }) {
      let index = state.documents.findIndex(document => document.id === case_relation_document_id);
      let fileIndex = state.documents[index].files.findIndex(docFile => docFile.id === file_id);
      state.documents[index].files.splice(fileIndex, 1);
    },
    updateDocumentFile(state, { case_relation_document_id, file }) {
      let index = state.documents.findIndex(document => document.id === case_relation_document_id);
      let fileIndex = state.documents[index].files.findIndex(docFile => docFile.id === file.id);
      if (fileIndex !== -1) state.documents[index].files.splice(fileIndex, 1, file);
    }
  }
};
