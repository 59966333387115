
<ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules" class="validated-select">
  <b-field :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0] }" :custom-class="verified ? 'is-verified' : ''">
    <b-select v-model="innerValue" :field="$attrs.name || $attrs.label" :disabled="disabled">
      <option v-for="option in options" :key="reduce(option)" :value="reduce(option)">
        {{ getOptionLabel(option) }}
      </option>
    </b-select>
  </b-field>
  <p class="help is-danger">{{ errors[0] }}</p>
</ValidationProvider>
