<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="() => {}">
      <div v-for="field in fieldsSorted" :key="field.id">
        <div v-if="isKindVisible(kind, field.type)" class="columns">
          <div v-if="field.type !== 'COLLECTION' && allowVerification" class="column is-1">
            <label class="label">Verified</label>
            <a @click="toggleStatus(entity, field.name)">
              <img v-if="entity && entity.verified && entity.verified[field.name]" src="@/assets/icons/figma/Checkbox.svg" style="height: 24px" />
              <img v-else src="@/assets/icons/figma/Checkbox-empty.svg" style="height: 24px" />
            </a>
          </div>
          <div class="column">
            <Field :horizontal="horizontal" v-model="entity[field.name]" :field="field" :locale="language" :case-id="caseId" :disabled="fieldDisabled(entity, field)" :verified="verified" />
          </div>
        </div>
      </div>
      <slot name="buttons">
        <div style="margin-top:1em; text-align:right;">
          <slot name="controls" :cancel="cancelForm" :submit="submitForm">
            <div class="buttons is-pulled-right">
              <button type="button" class="button is-outlined is-primary" @click="cancelForm">{{ $t('form.cancel') }}</button>
              <button type="button" class="button is-primary" @click="submitForm" v-if="!disabled">{{ $t('form.save') }}</button>
            </div>
          </slot>
        </div>
      </slot>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from 'vue';
import Field from './Field.vue';

export default {
  name: 'DynamicForm',
  components: { Field },

  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default() {
        return {};
      }
    },
    fields: {
      type: Array,
      default() {
        return [];
      }
    },
    language: {
      type: String,
      default: () => 'en'
    },
    rules: {
      type: Object,
      default() {
        return {};
      }
    },
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    allowVerification: {
      type: Boolean,
      default: false
    },
    caseId: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    kind: {
      type: String,
      default: null
    },
    verified: {
      type: Object,
      default: () => ({})
    },
    onSubmit: { type: Function, default: () => {} },
    onCancel: { type: Function, default: () => {} }
  },

  computed: {
    isBackOffice() {
      return this.$route.path.startsWith('/admin/');
    },
    fieldsSorted() {
      const copy = [].concat(this.fields);
      return copy.sort((a, b) => a.position - b.position);
    }
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    toggleStatus(entity, property) {
      if (!entity.verified) {
        Vue.set(entity, 'verified', {});
      }

      Vue.set(entity.verified, property, !entity.verified[property]);
    },

    /**
     * Submit the form (create entity)
     */
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$scrollToErrors();
        // ABORT!!
        return false;
      }

      try {
        this.onSubmit(this.entity);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async cancelForm() {
      try {
        this.onCancel();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    isKindVisible(kind, type) {
      if (kind === 'collection') {
        return type === 'COLLECTION';
      } else if (kind === 'claim') {
        return type !== 'COLLECTION';
      }
      return true;
    },
    fieldDisabled(entity, field) {
      if (this.isBackOffice) return false;
      if (this.disabled) return true;

      return !!this.verified[field.name];
    }
  }
};
</script>
