import CRUDService from './CRUDService';
import http from '../utils/http';

class CaseService extends CRUDService {
  constructor(path) {
    super(path);
  }

  exportCasesData({ case_id }) {
    return http({
      url: `api/cases/${case_id}/export/cases_data`,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      timeout: 0
    });
  }

  exportCollectionsData({ case_id }) {
    return http({
      url: `api/cases/${case_id}/export/collections_data`,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      timeout: 0
    });
  }

  async getMyClaims(params) {
    return http({
      url: `api/${this.path}/my-claims`,
      method: 'get',
      params
    });
  }

  generateContract(caseId) {
    return http({
      url: `api/${this.path}/${caseId}/generate-contract`,
      method: 'post',
      data: { caseId }
    });
  }

  regenerateContract(caseId) {
    return http({
      url: `api/${this.path}/${caseId}/regenerate-contract`,
      method: 'post',
      data: { caseId }
    });
  }

  /**
   * Change the claim's state (lifecycle)
   * @param {*} caseId          the case ID
   * @param {*} nextState       SUBMITTED or COMPLETED
   */
  updateLifecycle(caseId, nextState) {
    return http({
      url: `api/${this.path}/${caseId}/lifecycle`,
      method: 'put',
      data: { caseId, state: nextState }
    });
  }

  resendContract(caseId) {
    return http({
      url: `api/${this.path}/${caseId}/resend-contract`,
      method: 'post',
      data: { caseId }
    });
  }

  checkContract(caseId) {
    return http({
      url: `api/${this.path}/${caseId}/check-contract`,
      method: 'get',
      data: { caseId }
    });
  }

  isContractSigned(caseId) {
    return http({
      url: `api/${this.path}/${caseId}/check-contract-signature`,
      method: 'get',
      data: { caseId }
    });
  }

  getClaimData(caseId) {
    return http({
      url: `api/${this.path}/${caseId}/claimdata`,
      method: 'get',
      data: { caseId }
    });
  }

  upsertClaimData(caseId, data) {
    return http({
      url: `api/${this.path}/${caseId}/claimdata`,
      method: 'put',
      data
    });
  }

  upsertClaimDataVerifications(caseId, data) {
    return http({
      url: `api/${this.path}/${caseId}/claimdata/verifications`,
      method: 'put',
      data
    });
  }

  getFields(caseId, scope = 'all') {
    return http({
      url: `api/${this.path}/${caseId}/fields/${scope}`,
      method: 'get',
      data: { caseId }
    });
  }

  signContract(caseId, contractId) {
    return http({
      url: `api/${this.path}/${caseId}/sign-contract/${contractId}`,
      method: 'post',
      data: {
        signed_at: new Date()
      }
    });
  }

  countersignContract(caseId, contractId) {
    return http({
      url: `api/${this.path}/${caseId}/counter-sign-contract/${contractId}`,
      method: 'post',
      data: {
        signed_at: new Date()
      }
    });
  }

  uploadSignedContract(caseId, contractId, entity) {
    return http({
      url: `api/${this.path}/${caseId}/upload-signed-contract/${contractId}`,
      method: 'post',
      timeout: 0,
      data: entity
    });
  }

  deleteContract(caseId, contractId) {
    return http({
      url: `api/${this.path}/${caseId}/contract/${contractId}`,
      method: 'delete'
    });
  }

  uploadAdminSignedContract(caseId, contractId, entity) {
    if (contractId) {
      return http({
        url: `api/${this.path}/${caseId}/upload-admin-signed-contract/${contractId}`,
        method: 'post',
        timeout: 0,
        data: entity
      });
    } else {
      return http({
        url: `api/${this.path}/${caseId}/upload-admin-signed-contract`,
        method: 'post',
        timeout: 0,
        data: entity
      });
    }
  }

  getRelation(caseId, relationId, params) {
    return http({
      url: `api/cases/${caseId}/relations/${relationId}`,
      method: 'get',
      params
    });
  }

  getRelations(caseId, params) {
    return http({
      url: `api/cases/${caseId}/relations`,
      method: 'get',
      params
    });
  }

  createRelationFile(caseId, data) {
    return http({
      url: `api/cases/${caseId}/case-relation-document-files`,
      method: 'post',
      timeout: 0,
      data
    });
  }

  updateRelationFile(caseId, fileId, data) {
    return http({
      url: `api/cases/${caseId}/case-relation-document-files/${fileId}`,
      method: 'put',
      timeout: 0,
      data
    });
  }

  deleteRelationFile(caseId, fileId) {
    return http({
      url: `api/cases/${caseId}/case-relation-document-files/${fileId}`,
      method: 'delete'
    });
  }

  validateContractSignToken(caseId, token) {
    return http({
      url: `api/cases/${caseId}/validate-sign-token`,
      method: 'get',
      params: {
        token
      }
    });
  }

  getContract(caseId, contractId, params) {
    return http({
      url: `api/cases/${caseId}/contract/${contractId}`,
      method: 'get',
      params
    });
  }

  signClaimdataUpload(caseId, fileName) {
    return http({
      url: `api/cases/${caseId}/claimdata/sign-upload`,
      method: 'post',
      data: { caseId, fileName }
    });
  }

  duplicate(caseId, params) {
    return http({
      url: `api/cases/${caseId}/duplicate`,
      method: 'get',
      params
    });
  }

  getForms(caseId, params) {
    return http({
      url: `api/cases/${caseId}/forms`,
      method: 'get',
      params
    });
  }

  getFormFields(caseId, formId, params) {
    return http({
      url: `api/cases/${caseId}/form/${formId}/fields`,
      method: 'get',
      params
    });
  }

  upsertFormResponse(caseId, formId, data) {
    return http({
      url: `api/${this.path}/${caseId}/form/${formId}/response`,
      method: 'put',
      data
    });
  }
}

export default new CaseService('cases');
