import http from '../utils/http';

class UploadService {
  constructor(path) {
    this.path = path;
  }

  getPresignedPost(caseId, fileName) {
    return http({
      url: `api/${this.path}/sign`,
      method: 'post',
      data: { caseId, fileName }
    });
  }

  getPresignedPostContact(contactId, fileName) {
    return http({
      url: `api/${this.path}/sign-contact`,
      method: 'post',
      data: { contactId, fileName }
    });
  }

  getPresignedDirectory(directory, fileName) {
    return http({
      url: `api/${this.path}/sign-directory`,
      method: 'post',
      data: { directory, fileName }
    });
  }

  getSignedReadUrl(s3Url, filename) {
    return http({
      url: `api/${this.path}/sign-read`,
      method: 'post',
      data: { url: s3Url, filename }
    });
  }
}

export default new UploadService('uploads');
