import Vue from 'vue';
import '@/utils/environment-config';
import i18n from '@/i18n/i18n';
import './vee-validate';
import App from './App.vue';
import Buefy from 'buefy';
import router from '@/router/index';
import store from './store/store';
import filters from '@/common/filters';
import VTooltip from 'v-tooltip';
import ResponsiveAutocomplete from '@/components/ResponsiveAutocomplete';
import InlineIcon from '@/components/InlineIcon.vue';
import ColorPicker from '@/components/ColorPicker.vue';
import dayjs from 'dayjs';

import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import DynamicTableRowDialog from '@/modules/dynamic-form/components/DynamicTableRowDialog.vue';

Date.prototype.toJSON = function() {
  return dayjs(this).format();
};

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err);
};

Vue.config.productionTip = false;

Vue.component('InlineIcon', InlineIcon);
Vue.component('ColorPicker', ColorPicker);

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'InlineIcon'
});

Vue.use(VTooltip);

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.component('RAutocomplete', ResponsiveAutocomplete);
Vue.component('DynamicTableRowDialog', DynamicTableRowDialog);

VueSvgInlinePlugin.install(Vue, {
  attributes: {
    data: ['src'],
    remove: ['alt']
  },
  cache: {
		persistent: false,
		removeRevisions: true
  }
});

Vue.filter('excerpt', function(html, length) {
  if (!length) length = 200;
  const d = document.createElement('div');
  d.innerHTML = html;
  const rawText = d.textContent;
  if (rawText.length <= length) return rawText;
  const text = rawText.substr(0, length);
  return text ? text + '...' : '';
});

store.dispatch('i18n/initLang');
store.dispatch('theme/initTheme');
store.dispatch('notification/refreshNotification');

const whitelabel = Object.assign(
  {
    id: document.querySelector('meta[name="whitelabel:id"]')?.getAttribute('content') || null
  },
  window.whitelabel
);
store.dispatch('whitelabel/changeWhiteLabel', whitelabel);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
