// import BAutocomplete from 'buefy/src/components/autocomplete/Autocomplete.vue';
import {BAutocomplete} from 'buefy/dist/cjs/autocomplete';

export default Object.assign({}, BAutocomplete, {
  name: 'ResponsiveAutocomplete',
  methods: Object.assign({}, BAutocomplete.methods, {
    calcDropdownInViewportVertical() {
      this.$nextTick(() => {
        if (this.$refs.dropdown === undefined) return;

        const dropdownEl = this.$refs.dropdown;

        dropdownEl.style.position = 'fixed';
        dropdownEl.style.zIndex = 9999999;

        const mainRect = this.$el.getBoundingClientRect();

        const wheight = window.innerHeight;

        if (wheight - mainRect.bottom >= mainRect.top) {
          dropdownEl.style.top = mainRect.bottom + 'px';
          dropdownEl.style.bottom = 'initial';
        } else {
          dropdownEl.style.top = 'initial';
          dropdownEl.style.bottom = wheight - mainRect.top + 'px';
        }

        dropdownEl.style.left = mainRect.left + 'px';
        dropdownEl.style.minWidth = 'initial';

        const rect = dropdownEl.getBoundingClientRect();

        this.isListInViewportVertically = rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      });
    }
  })
});
