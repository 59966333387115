<template>
  <div class="admin-layout">
    <Navbar />
    <div class="container is-fluid">
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from '@/layout/Navbar.vue';
export default {
  name: 'AdminNoSidebarLayout',
  components: {
    Navbar
  }
};
</script>
