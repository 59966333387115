import i18n from '@/i18n/i18n';

import I18nService from '@/services/I18nService';

const initialState = () => ({
  lang: 'en',
  lang_loaded: []
});

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    initLang({ dispatch }) {
      const lang = localStorage.getItem('lang') || 'en';
      dispatch('changeLang', lang);
    },
    async changeLang({ state, commit }, lang) {
      if (state.lang_loaded.indexOf(lang) === -1) {
        const messages = await I18nService.loadMessages(lang);
        commit('addLangMessages', { lang, messages });
      }
      commit('setLang', lang);
      i18n.locale = lang;
      document.querySelector('html').setAttribute('lang', lang); // TODO fixme
    }
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
      localStorage.setItem('lang', lang);
    },
    addLangMessages(state, { lang, messages }) {
      state.lang_loaded.push(lang);
      i18n.setLocaleMessage(lang, messages);
    }
  }
};
