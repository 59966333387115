<template>
  <UploadControl
    :accept="accept"
    :horizontal="horizontal"
    :label="label"
    :get-signed-url="fileName => generateSignedUrl(fileName)"
    :verified="verified"
    :disabled="disabled"
    :message="message"
    :value="internal"
    @update:value="internal = $event"
  />
</template>

<script>
import CaseService from '@/services/CaseService';
import FrontendService from '@/services/FrontendService';
import UploadControl from '@/components/form/UploadControl.vue';
export default {
  components: {
    UploadControl
  },

  props: {
    accept: {
      type: String,
      default: '*/*'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: () => 'File'
    },
    caseId: {
      type: String,
      default: null
    },
    verified: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },

  computed: {
    internal: {
      get() {
        return this.value;
      },
      set(newValue) {
        // When the internal value changes, we $emit an event. Because this event is
        // named 'input', v-model will automatically update the parent value
        this.$emit('input', newValue);
      }
    }
  },

  methods: {
    async generateSignedUrl(fileName) {
      if (this.caseId === 'new') {
        const response = await FrontendService.signClaimDataUpload(fileName);
        return response.data;
      } else {
        const response = await CaseService.signClaimdataUpload(this.caseId, fileName);
        return response.data;
      }
    },
    isValid() {
      return this.internal;
    }
  }
};
</script>
