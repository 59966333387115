<template>
  <div>
    <UploadMultipleControl
      :accept="accept"
      :horizontal="horizontal"
      :label="label"
      :get-signed-url="fileName => generateSignedUrl(fileName)"
      :verified="verified"
      :disabled="disabled"
      :message="message"
      :value="internal"
      @update:value="internal = $event"
    >
      <div class="control image-input">
        <ul class="placeholder">
          <li>TEST</li>
        </ul>
      </div>
    </UploadMultipleControl>
  </div>
</template>

<script>
import CaseService from '@/services/CaseService';
import UploadMultipleControl from '@/components/form/UploadMultipleControl.vue';
import FrontendService from '@/services/FrontendService';
export default {
  components: {
    UploadMultipleControl
  },

  props: {
    accept: {
      type: String,
      default: '*/*'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: () => 'File'
    },
    caseId: {
      type: String,
      default: null
    },
    verified: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },

  computed: {
    internal: {
      get() {
        return this.value;
      },
      set(newValue) {
        // When the internal value changes, we $emit an event. Because this event is
        // named 'input', v-model will automatically update the parent value
        this.$emit('input', newValue);
      }
    }
  },

  methods: {
    async generateSignedUrl(fileName) {
      if (this.caseId === 'new') {
        const response = await FrontendService.signClaimDataUpload(fileName);
        return response.data;
      } else {
        const response = await CaseService.signClaimdataUpload(this.caseId, fileName);
        return response.data;
      }
    },
    isValid() {
      return this.internal.length > 0;
    }
  }
};
</script>
