/* eslint-disable camelcase */
import Vuex from 'vuex';
import Vue from 'vue';
import alert from './modules/alert.module';
import authentication from './modules/authentication.module';
import breadcrumb from './modules/breadcrumb.module';
import intakeold from './modules/intakeold.module';
import intakeng from './modules/intakeng/intakeng.module';
import i18n from './modules/i18n.module';
import notification from './modules/notification.module';
import navigation from './modules/navigation.module';
import whitelabel from './modules/whitelabel.module';
import theme from './modules/theme.module';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  actions: {
    resetAll({ commit, dispatch }) {
      commit('alert/reset');
      commit('authentication/reset');
      commit('breadcrumb/reset');
      //commit('user/reset');
    }
  },
  modules: {
    alert,
    authentication,
    breadcrumb,
    intakeold,
    intakeng,
    i18n,
    notification,
    navigation,
    whitelabel,
    theme
    // user
  },
  plugins: []
});
