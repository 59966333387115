<template>
  <div class="field">
    <div :class="{ 'field-label-table-horizontal': horizontal }">
      <label class="label">{{ collection.label }}{{ collection.is_required ? '*' : '' }}</label>
      <p v-if="message" class="help">{{ message }}</p>
    </div>
    <div :class="{ 'field-body': horizontal, 'field-body-table-horizontal': horizontal }">
      <div class="field">
        <div class="dynamic-data-table view-table page-filtered">
          <b-table :data="innerValue" class="sket-table" @click="openEditRowDialog">
            <template slot-scope="props">
              <b-table-column label="Verified" width="20">
                <img v-if="verified[props.row.id]" src="@/assets/icons/figma/Checkbox.svg" style="height: 24px" />
                <img v-if="!verified[props.row.id]" src="@/assets/icons/figma/Checkbox-empty.svg" style="height: 24px" />
              </b-table-column>
              <b-table-column v-for="(c, index) in collectionChildrenSorted" :key="index" :field="c.name" :label="c.label">
                <DynamicDataValueRenderer :field="c" :type="c.type" :value="props.row[c.name]" />
              </b-table-column>
              <b-table-column v-if="collection.visibility !== 'READ_ONLY' && !fieldDisabled(props.row)" class="actions">
                <a @click.prevent.stop="openEditRowDialog(props.row)">
                  <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/pencil.svg')" />
                </a>
                <a @click.prevent.stop="openConfirmDeleteDialog(props.row, index)">
                  <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/trash.svg')" />
                </a>
              </b-table-column>
              <b-table-column v-else></b-table-column>
            </template>
            <template slot="empty">
              <div class="has-text-grey">
                No data has been added
              </div>
            </template>
            <template slot="footer">
            </template>
          </b-table>
        </div>
        <div class="table-toolbar">
          <a v-if="collection.visibility !== 'READ_ONLY' && !disabled" @click="openAddRowDialog">
                  <span class="link-icon-primary">
                    <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/plus.svg')" />
                  </span>
            Add a row
          </a>
          <div class="is-pulled-right">
            <a @click.prevent.stop="openConfirmDeleteAllDialog()" v-if="isBackOffice"><img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/trash.svg')" /> Delete all</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicDataValueRenderer from '@/modules/dynamic-form/components/DynamicDataValueRenderer.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
export default {
  name: 'DynamicTable',

  components: {
    DynamicDataValueRenderer
  },

  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    collection: {
      type: Object,
      default: () => {}
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    caseId: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    innerValue: []
  }),

  computed: {
    isBackOffice() {
      return this.$route.path.startsWith('/admin/');
    },
    columns() {
      return this.collection.children.map(field => {
        return { field: field.name, label: field.label };
      });
    },
    collectionChildrenSorted() {
      const copy = [].concat(this.collection.children);
      return copy.sort((a, b) => a.position - b.position);
    }
  },

  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },

  methods: {
    openAddRowDialog() {
      if (this.disabled) return;

      const DynamicTableRowDialog = Vue.component('DynamicTableRowDialog');
      this.$buefy.modal.open({
        parent: this,
        component: DynamicTableRowDialog,
        hasModalCard: true,
        props: {
          editMode: false,
          fields: this.collection.children,
          caseId: this.caseId,
          onSubmit: async result => {
            this.innerValue.push({ id: uuidv4(), ...result });
          }
        }
      });
    },
    openEditRowDialog(row, index) {
      if (this.disabled) return;

      const DynamicTableRowDialog = Vue.component('DynamicTableRowDialog');
      this.$buefy.modal.open({
        parent: this,
        component: DynamicTableRowDialog,
        hasModalCard: true,
        props: {
          editMode: true,
          editEntity: row,
          fields: this.collection.children,
          disabled: this.fieldDisabled(row),
          caseId: this.caseId,
          onSubmit: async result => {
            //this.innerValue.push(result);
          }
        }
      });
    },
    openConfirmDeleteAllDialog() {
      if (this.disabled) return;

      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        props: {
          title: 'Delete rows?',
          message: 'Are you sure you want to delete all rows?',
          onOk: async () => {
            this.innerValue.splice(0, this.innerValue.length);
          }
        }
      });
    },
    openConfirmDeleteDialog(row, index) {
      if (this.disabled) return;

      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        props: {
          title: 'Delete this claim?',
          message: 'Are you sure you want to delete this row? This action cannot be undone.',
          onOk: async () => {
            this.innerValue.splice(index, 1);
          }
        }
      });
    },
    fieldDisabled(entity) {
      if (this.isBackOffice) return false;
      if (this.disabled) return true;

      return !!this.verified[entity.id];
    }
  }
};
</script>

<style lang="scss" scoped>
.dynamic-data-table {
  background: #fff;
  border: 1px solid $grayscale-3;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;

  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
  }
}

.table-toolbar {
  margin: 0 14px;
}
</style>
