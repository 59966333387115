<template>
  <div class="has-text-weight-semibold">
    <span v-if="type === 'CURRENCY'">{{ value && value.name && value.value ? `${CURRENCIES[value.name].symbol}${value.value}` : '-' }}</span>
    <span v-else-if="type === 'DATE'">{{ value | formatDate }}</span>
    <span v-else-if="type === 'UPLOAD'"
      ><a @click="goToS3(value)">{{ getFileName(value) }}</a></span
    >
    <span v-else-if="type === 'UPLOAD_MULTIPLE'">
      <span v-for="(upload, idx) in value" :key="idx">
        <a @click="goToS3(upload.url, upload)">{{ upload.legacy_name || getFileName(upload.url) }}</a> <span>{{ upload.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span
        ><br v-if="idx < value.length - 1" />
      </span>
    </span>
    <span v-else-if="type === 'UPLOAD_SIGNED'"
      ><span v-if="value && value.document_to_be_signed"
        >Document to sign: <a @click="goToS3(value.document_to_be_signed)">{{ getFileName(value.document_to_be_signed) }}</a></span
      >
      -
      <span v-if="value && value.value"
        >Uploaded document: <a @click="goToS3(value.value)">{{ getFileName(value.value) }}</a></span
      ></span
    >
    <span v-else-if="type === 'RADIO'">{{ localizeOption(value) }}</span>
    <span v-else-if="type === 'CHECKBOX'">{{ value ? value.map(item => localizeOption(item)).join(', ') : '' }}</span>
    <span v-else-if="type === 'CONSENT'">
      <span v-if="value">{{ value.consented_by }} consented at {{ value.consented_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
      <span v-else>No response received yet</span>
    </span>
    <span v-else class="has-text-weight-semibold">{{ value }}</span>
  </div>
</template>

<script>
import Utils from '@/utils';
import { mapState } from 'vuex';
import currencyList from '@/utils/currencies.json';
import UploadService from '@/services/UploadService';

export default {
  props: {
    type: {
      type: String,
      default: null
    },
    value: {
      type: null,
      default: null
    },
    field: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      CURRENCIES: currencyList
    };
  },

  computed: {
    ...mapState('i18n', {
      locale: 'currentLang'
    }),
    localisedField() {
      if (!this.locale || this.locale === 'en') {
        return this.field;
      } else {
        let translation = this.field.translations.find(translation => {
          return translation.lang === this.locale;
        });

        return translation || { label: this.field.label };
      }
    }
  },

  methods: {
    getFileName(value) {
      if (!value) return '';
      return Utils.getFilename(value);
    },
    localizeOption(value) {
      const field = this.field.options.find(item => item.value === value);
      if (!field) return value;

      return field[this.locale] || field.label;
    },
    async goToS3(url, upload) {
      const filename = upload?.legacy_name || null;
      const presigned = await UploadService.getSignedReadUrl(url, filename);
      window.open(presigned.data.url, '_blank');
    }
  }
};
</script>
