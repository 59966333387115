<template>
  <div>
    <UserNavbar></UserNavbar>
    <router-view />
  </div>
</template>

<script>
import UserNavbar from '@/layout/UserNavbar.vue';
export default {
  components: {
    UserNavbar
  }
};
</script>
