import NotificationsService from '@/services/NotificationsService';

const initialState = () => ({
  notificationsCount: 0,
  intervalHandler: null
});

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async refreshNotification({ state, dispatch, commit, rootState }) {
      if (rootState.authentication.accessToken && rootState.authentication.user && rootState.authentication.user.global_role === 'ADMIN') {
        const response = await NotificationsService.getAll({ is_seen: false });
        commit('setCount', response.data.data.filter(item => !item.is_seen).length);
      }

      if (!state.intervalHandler) {
        // state.intervalHandler = setInterval(() => {
        //   dispatch('refreshNotification');
        // }, 15000);
      }
    }
  },
  mutations: {
    setCount(state, value) {
      state.notificationsCount = value;
    }
  }
};
