
<div class="field upload-control" :class="{ 'is-horizontal': horizontal }">
  <div :class="{ 'field-label': horizontal }">
    <label v-if="!hideLabel" class="label mb-2">
      <div v-if="verified" class="is-verified"></div>
      {{ label }}
    </label>
  </div>
  <div v-if="!disabled" :class="{ 'field-body': horizontal }">
    <div class="field">
      <div class="control image-input card-document-upload" @click="chooseImage">
        <div v-if="isUploading" class="placeholder has-file-selected">
          <div style="width: 100%;">
            <div style="text-align: center;">
              <a @click.prevent.stop="goToS3(internalValue, displayFileName)">{{ displayFileName }}</a>
            </div>
            <div><b-progress type="is-info" :value="percentCompleted" /></div>
          </div>
        </div>
        <div v-else-if="!internalValue" class="placeholder">
        <span class="back-icon">
          <img v-svg-inline class="inline-icon arrow-up" :src="require('@/assets/icons/fa/far/arrow-up.svg')" />
        </span>
        <a class="deselect-upload" @click.prevent.stop="clearFile()"><img src="@/assets/icons/trash.svg"/></a>

          {{ $t('common.upload') }} {{ label }}
        </div>
        <div v-else class="placeholder has-file-selected">
          <div style="width: 100%;">
            <div style="text-align: center;">
              <a @click.prevent.stop="goToS3(internalValue, displayFileName)">{{ displayFileName }}</a>
            </div>
          </div>
          <a class="deselect-upload" @click.prevent.stop="clearFile()"><img src="@/assets/icons/trash.svg"/></a>
        </div>

        <input ref="fileInput" class="file-input" type="file" :accept="accept" @change="fileChange($event)" />
      </div>
      <p v-if="message" class="help">{{ message }}</p>
    </div>
  </div>
  <div v-else :class="{ 'field-body': horizontal }">
    <div class="control image-input disabled">
      <div class="placeholder has-file-selected">
        <div style="width: 100%;">
          <div style="text-align: center;">
            <a @click.prevent.stop="goToS3(internalValue, displayFileName)">{{ displayFileName }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p v-if="errors" class="help has-text-danger">{{ errors[0] }}</p>
</div>
