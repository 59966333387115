import http from '../utils/http';

class CRUDService {
  constructor(path) {
    this.path = path;
  }

  async getAll(params) {
    return http({
      url: `api/${this.path}`,
      method: 'get',
      params
    });
  }

  async getOne(id, params) {
    return http({
      url: `api/${this.path}/${id}`,
      method: 'get',
      params
    });
  }

  create(data) {
    return http({
      url: `api/${this.path}`,
      method: 'post',
      data
    });
  }

  update(id, data) {
    return http({
      url: `api/${this.path}/${id}`,
      method: 'put',
      data
    });
  }

  delete(id) {
    return http({
      url: `api/${this.path}/${id}`,
      method: 'delete'
    });
  }

  async deleteAll(params) {
    return http({
      url: `api/${this.path}`,
      method: 'delete',
      params
    });
  }
}

export default CRUDService;
