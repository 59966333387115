
<form action>
  <div class="modal-card" style="width: 600px">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <p>{{ message }}</p>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button type="button" class="button" @click="onCancel">Cancel</button>
        <button ref="okBtn" type="button" autofocus class="button is-primary" @click="clickedOk">Ok</button>
      </div>
    </footer>
  </div>
</form>
